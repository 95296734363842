import _ from 'lodash';
import React from 'react';
import { Divisor } from '../../components/divisor';
import { Skeleton } from '../../components/skeleton';

function SummaryLoading(props: {
  n: number;
}) {
  return (
    <div className="summary-container">
      <div className="summary-title">
        Riepilogo
      </div>
      <div className="summary-body">
        {
          _.times(props.n,
            (n) => (
              <div className="summary-body-element">
                <Skeleton style={{ height: '19px', width: '55%' }} />
                <Skeleton style={{ height: '19px', width: '20%' }} />
              </div>
            ))
        }
        <div style={{ marginTop: '45px' }} className="summary-body-element">
          <div className="summary-body-element-name">
            Spedizione
          </div>
          <Skeleton style={{ height: '19px', width: '20%' }} />
        </div>
        <Divisor />
        <div className="summary-body-element">
          <div className="summary-body-element-name">
            Totale
          </div>
          <Skeleton style={{ height: '19px', width: '20%' }} />
        </div>
      </div>
    </div >
  )
}

export { SummaryLoading }