import React, { useEffect } from 'react';
import braintree from 'braintree-web';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

function Pay() {

  let navigate = useNavigate()

  useEffect(() => {
    braintree.client.create({
      authorization: 'sandbox_gp8pt7yj_gx4p44csy3t93jjh'
    }, function (err, clientInstance) {
      if (err) {
        console.error(err);
        return;
      }

      braintree.hostedFields.create({
        client: clientInstance,
        fields: {
          cardholderName: {
            selector: '#cc-name',
            prefill: "IIII",
            placeholder: 'Name as it appears on your card'
          },
          number: {
            selector: '#cc-number',
            prefill: "5105105105105100",
            placeholder: '4111 1111 1111 1111'
          },
          cvv: {
            selector: '#cc-cvv',
            prefill: "123",
            placeholder: '123'
          },
          expirationDate: {
            selector: '#cc-expiration',
            prefill: "01/24",
            placeholder: 'MM / YY'
          }
        }
      }, function (err, hostedFieldsInstance) {
        if (err) {
          console.error(err);
          return;
        }

        hostedFieldsInstance?.tokenize(function (err, payload) {
          if (err) {
            console.error(err);
            return;
          }

          // This is where you would submit payload.nonce to your server
          console.log(payload?.nonce)

          axios.post('/api/pay', {
            nonce: payload?.nonce,
            amount: 10.04,
            email: "prova@esempio.com",
            address_city: "Milano",
            address_zipcode: "11123",
            address_street: "Via xxx",
            address_region: "Milano",
            first_name: "Tomm",
            last_name: "Ama",
            payment_method: "credit"
          }).then((data) => {
            console.log("PAY.tsx", data.data)
            navigate(`/accepted?id=${data.data}`)
          }).catch((err) => {
            console.log(err.status);
            navigate('/rejected')
          })
          // you can either send the form values with the payment
          // method nonce via an ajax request to your server,
          // or add the payment method nonce to a hidden inpiut
          // on your form and submit the form programatically
          // $('#payment-method-nonce').val(payload.nonce);
          // form.submit()
        });
      })
    })
  }, []);

  return (
    <div className="App">
      pagato
      <div id="cc-name" />
      <div id="cc-number"></div>
      <div id="cc-cvv">123</div>
      <div id="cc-expiration">01/24</div>
    </div>
  );
}

export default Pay;
