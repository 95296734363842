import axios, { AxiosResponse } from "axios";
import { BillType, RequestPayType, RequestSendMailType, ResponsePayType } from "../../../types";
import { OrderType } from "../../../types/checkout";
import { FormInputsType } from "../../../types/form";
import { removeAllCookie } from "../cookie_helper";
import { loadStripe, Stripe, StripeElements, StripeError } from "@stripe/stripe-js";
import { CardElement, CardNumberElement } from "@stripe/react-stripe-js";

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!)

// async function getClientSecret(): Promise<String> {
//   const amount = 1200
//   const response = await axios.post("/api/paymentIntent", { amount })

//   return response.data.clientSecret
// }

async function createPaymentMethod(stripe: Stripe, elements: StripeElements) {
  stripe.createPaymentMethod({
    type: 'card',
    card: elements.getElement(CardNumberElement)!
  }).then(console.log).catch(console.log)
}

async function handlePayment(
  stripe: any,
  paymentMethodId: string,
  idempotencyKey: string,
  customer: FormInputsType,
  order: OrderType,
  onSuccess: { (data: any): void; (arg0: any): void; },
  onError: ((arg0: any) => void),
) {

  console.log("paymentMethodId", paymentMethodId)

  // payment_method_id: paymentMethodId,
  const payBody: RequestPayType = {
    idempotency_key: idempotencyKey,
    email: customer.email,
    address_city: customer.city,
    address_zipcode: customer.zipcode,
    address_street: customer.address,
    address_civic_number: customer.civic_number,
    address_region: customer.region,
    first_name: customer.first_name,
    last_name: customer.last_name,
    phone: customer.phone,
    order,
    payment_method: "credit card"
  }

  axios.post('/api/pay', { ...payBody, payment_method_id: paymentMethodId })
    .then(async (res) => {
      const result = await handleSuccess(res, stripe, payBody)
      console.log(result)
      if (result.bill) {
        const reqSendMail: RequestSendMailType = {
          customer,
          order: result.bill.order,
          order_id: result.bill.order_id
        }
        axios.post('/auth_api/sendMail', reqSendMail, {
          headers: {
            'authorization-api-key': process.env.REACT_APP_AUTHORIZATION_API_KEY!
          }
        })
        console.log("BILL in arrivo", result.bill)
        removeAllCookie()
        console.log("EVVAI")
        onSuccess(result.bill)
      } else {
        throw Error(result.error!.message)
      }
    })
    .catch((err) => {
      // console.log("EEORERER", err)
      //removeAllCookie()
      console.log("handlePayment: ERROR -", err);
      onError(err)
    })

}

async function handleSuccess(res: any, stripe: Stripe, payBody: any): Promise<{ bill?: BillType, error?: StripeError }> {
  console.log("HELP ", res, stripe);

  const { data } = res;
  if (data.requires_action) {
    // Use Stripe.js to handle the required card action
    const { error: errorAction, paymentIntent } = await stripe.handleCardAction(data.payment_intent_client_secret);

    if (errorAction) {
      console.log("ERRORE", errorAction);
      return { error: errorAction };
      // Show error from Stripe.js in payment form
    } else {
      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server
      const serverResponse = await axios.post('/api/pay', { ...payBody, payment_intent_id: paymentIntent!.id });
      console.log("server response", serverResponse);
      return (await handleSuccess(serverResponse, stripe, payBody));
    }
  } else {
    console.log("DATA", data);
    console.log("res", res);
    return { bill: data.bill };
    //Promise.resolve(data.bill)
  }
}

export { createPaymentMethod, handlePayment }