import React from "react";
import { NavLink } from "react-router-dom";
import * as Colors from "../constants/colors";
import { CartIcon, CartNavElement } from "./navbar/cart_icon";
import cart_pink from '../assets/icon/bag.png'
// import cart_pink from '../assets/icon/shopping-cart-pink.png'
import { Divisor } from "./divisor";

const toHome = "/"
const toShop = "/shop"
const toCart = "/cart"

function SlidingPanel(props: {
  status: boolean,
  handleClose: React.MouseEventHandler<HTMLDivElement> | undefined
}) {

  return (
    <div className={`sliding-panel-container sliding-panel-${props.status ? 'visible' : 'hidden'}`}>
      <div
        className="sliding-panel-header">
        <div
          onClick={props.handleClose}
          className="sliding-panel-header-close">
          CHIUDI
        </div>
      </div>
      <div className="sliding-panel-body">
        <NavLink className={({ isActive }) => isActive ? "sliding-panel-item active" : "sliding-panel-item"}
          to={toHome}>home</NavLink>
        {/* <Divisor style={{
          backgroundColor: "#cc4ba4",
          margin: '0 6px'
        }} /> */}
        <NavLink
          className={({ isActive }) => isActive ? "sliding-panel-item active" : "sliding-panel-item"}
          to={toShop}>
          shop
        </NavLink>
        {/* <NavLink className="sliding-panel-item" to="/contattaci">Contattaci</NavLink> */}
        <CartNavElement
          className="sliding-panel-item"
          to={toCart}
          cartIcon={cart_pink}
          colorCartNotify={Colors.FUCSIA}
          colorCartNotifyBorder={'white'}
        />

      </div>
    </div>
  )
}

export { SlidingPanel } 