import React, { useState } from "react";
import { CandorlanaeImage } from '../components';

import { ProductType } from "../../types";
import { displayPrice } from "../helper/displayer";
import { AddToCart } from "../components/add_to_cart_button";
import { ColorSelect } from "../components/product/color_select";
import { SizeSelect } from "../components/product/size_select";
import { ColorOptionType, SizeOptionType } from "../../types/option";
import { ImageSlider } from "../components/product/image_slider";
import eco_icon from '../assets/icon/eco.png'
import sheep_icon from '../assets/icon/sheep.png'
import diamond_icon from '../assets/icon/diamond.png'
import wool_icon from '../assets/icon/wool-ball.png'
import { Collapsible } from "../components/collapsible";

import cart_icon from '../assets/icon/send2.png'

import next from '../assets/icon/arrow_forward.svg'
import back from '../assets/icon/arrow_back.svg'

import { CustomerRequestBox } from "../components/product/customer_request_box";

import { SizeGuide } from "../components/product/size_guide";

function ProductMain(props: { product: ProductType }) {

  const { product } = props;
  const [colorSelected, setColorSelected] = useState(0)
  const [sizeSelected, setSizeSelected] = useState(0)
  const [imageIndex, setImageIndex] = useState(0)

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const nImgs = (colorIndex: number) => product!.model_info[colorIndex].imgs.length
  const computeCurrentImageIndex = (index: number) => {
    if (imageIndex >= 0)
      return index + (imageIndex - (imageIndex % nImgs(colorSelected)));

    return index + (imageIndex + (imageIndex % nImgs(colorSelected)));
  }

  let handleChangeColor = (elem: ColorOptionType) => {
    scrollToTop()
    setColorSelected(elem.index)
    setSizeSelected(0)
    setImageIndex(0)
  }

  let handleChangeSize = (elem: SizeOptionType) => {
    setSizeSelected(elem.index)
  }

  let handleClickOtherImg = (index: number) => {
    setImageIndex(computeCurrentImageIndex(index))
  }

  return (
    <>
      <div className="product-body-main">
        <div className="product-otherimage-body">
          {
            product?.model_info[colorSelected].imgs
              .map((item, index) =>
                <div key={item}>
                  <CandorlanaeImage
                    externalImg={{
                      src: item,
                      size: "normal"
                    }}
                    percentage={'129%'}
                    onClick={() => handleClickOtherImg(index)}
                    containerClassName="product-otherimage-container"
                    imgStyle={{ borderRadius: '5px' }}
                    imgClassName={index == Math.abs(imageIndex) % nImgs(colorSelected) ? "" : "product-otherimage-unselected"}
                  />
                </div>
              )
          }
        </div>
        <ImageSlider
          imageIndex={imageIndex}
          imgs={product.model_info[colorSelected].imgs}
          onBack={() => setImageIndex(imageIndex - 1)}
          onNext={() => setImageIndex(imageIndex + 1)}
          onClickDot={(index) => setImageIndex(computeCurrentImageIndex(index))}
          onChangeImage={(i) => setImageIndex(i)}
        />

        <div className="product-info-container">
          <div className="product-info-title">
            {product?.name}
          </div>
          <div className="product-info-price">
            {displayPrice(product?.price)}
          </div>
          <div className="product-info-details-container">
            <div className="product-info-color-container">
              <div className="product-info-details-title product-info-color-title">
                Colore:
              </div>
              <ColorSelect
                colorIndex={colorSelected}
                product={product}
                onChange={(x) => handleChangeColor(x)}
              />
            </div>
            <div className="product-info-size-container">
              <div className="product-info-size" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className="product-info-details-title" style={{ marginRight: '5px' }}>
                  Taglia:
                </div>
                <div style={{ display: 'flex', alignItems: "center", cursor: 'pointer' }}>

                </div>
                <SizeGuide guides={["Altezza: 15cm", "Larghezza: 30cm"]} sizeName={product.model_info[colorSelected].sizes[sizeSelected].size} />
              </div>
              <SizeSelect
                sizeIndex={sizeSelected}
                model={product.model_info[colorSelected]}
                onChange={(x) => handleChangeSize(x)}
              />
            </div>
          </div>
          <div className="product-info-details-title product-info-color-title">
            Qualità:
          </div>
          <div className="product-qualities">
            {product.qualities.vegan && <div className="product-quality-container">
              <img src={eco_icon} width="23" style={{ marginRight: '5px' }} />
              Cotone vegano
            </div>}
            {product.qualities.valuable && <div className="product-quality-container">
              <img src={diamond_icon} width="23" style={{ marginRight: '5px' }} />
              Materiale pregiato
            </div>}
            {product.qualities.sheep && <div className="product-quality-container">
              <img src={sheep_icon} width="23" style={{ marginRight: '5px' }} />
              Pecora transumante
            </div>}
            {product.qualities.wool && <div className="product-quality-container">
              <img src={wool_icon} width="23" style={{ marginRight: '5px' }} />
              100% lana
            </div>}
          </div>
          {product && <AddToCart
            className="product-info-addtocart"
            colorButton='fucsia'
            _id={product._id!.toString()}
            modelIndex={colorSelected}
            sizeIndex={sizeSelected}
            disabled={product.model_info[colorSelected].sizes[sizeSelected].stock <= 0}
          />}
          <div className="product-unavailable">
            {product.model_info[colorSelected].sizes[sizeSelected].stock <= 0 ? '' : ''}
          </div>
          <CustomerRequestBox
            enabled={product.model_info[colorSelected].sizes[sizeSelected].stock > 0}
            ttd={product.ttd}
            _id={product._id?.toString()!}
            name={product.name}
            color={product.model_info[colorSelected].color.name}
            size={product.model_info[colorSelected].sizes[sizeSelected].size}
          />
          <div className="product-info-description">
            {product?.description}
          </div>
        </div>
      </div>
      <div className="product-features-body">
        <div className="product-features-container">
          <div className="product-features-title">
            Dettagli e materiali
          </div>
          <div className="product-features-description">
            {product.materials_info?.map(i => (
              <div key={i} className="product-features-description-element">
                {i}
              </div>
            ))}
          </div>
        </div>
        <div className="product-features-container">
          <div className="product-features-title">
            Vestibilità e misure
          </div>

          <div className="product-features-description">
            {product.fit_info?.map(i => (
              <div key={i} className="product-features-description-element">
                {i}
              </div>
            ))}
          </div>
        </div>
        <div className="product-features-container ">
          <div className="product-features-title">
            Cura
          </div>
          <div className="product-features-description">
            {product.care_info?.map(i => (
              <div key={i} className="product-features-description-element">
                {i}
              </div>
            ))}
          </div>
          {/* <div className="product-features-title margined">
            Codice prodotto
          </div>
          <div className="product-features-description">
            <div className="product-features-description-element">
              {product.sku}
            </div>
          </div> */}
        </div>
      </div>

    </>
  )
}

export { ProductMain }
