import React from 'react';
import { ActionMeta } from 'react-select';
import SingleValue from 'react-select/dist/declarations/src/components/SingleValue';
import { ModelInfoType, ProductType } from '../../../types';
import { SizeOptionType } from '../../../types/option';
import { CustomSelect } from './custom_select';
import { SizeOption } from './custom_select_option';
import { SizeSigleValue } from './custom_select_singlevalue';

function SizeSelect(props: {
  sizeIndex: number,
  model: ModelInfoType,
  onChange: ((newValue: SizeOptionType, actionMeta: ActionMeta<any>) => void) | undefined
}) {

  const getSizeOption = (sizeIndex: number): SizeOptionType => {
    return {
      label: props.model.sizes[sizeIndex].size,
      index: sizeIndex
    }
  }

  return (
    <CustomSelect
      value={getSizeOption(props.sizeIndex)}
      defaultValue={getSizeOption(0)}
      onChange={props.onChange}
      Option={SizeOption}
      SingleValue={SizeSigleValue}
      options={
        props.model.sizes.map((label, index, arr) => {
          return { label: label.size, isLast: index == arr.length - 1, index }
        })
      }
    />
  )
}

export { SizeSelect }