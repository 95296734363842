import React from 'react';
import { ActionMeta } from 'react-select';
import { ProductType } from '../../../types';
import { ColorOptionType } from '../../../types/option';
import { CustomSelect } from './custom_select';
import { ColorOption } from './custom_select_option';
import { ColorSingleValue } from './custom_select_singlevalue';

function ColorSelect(props: {
  product: ProductType,
  colorIndex: number,
  onChange: ((newValue: ColorOptionType, actionMeta: ActionMeta<any>) => void) | undefined
}) {

  const getColorOption = (index: number): ColorOptionType => {
    return {
      label: props.product.model_info[index].color.name,
      code: props.product.model_info[index].color.code,
      code2: props.product.model_info[index].color.code2,
      index
    }
  }
  return (
    <CustomSelect
      value={getColorOption(props.colorIndex)}
      defaultValue={getColorOption(0)}
      onChange={props.onChange}
      Option={ColorOption}
      SingleValue={ColorSingleValue}
      options={
        props.product.model_info.map((model, index: number, arr): ColorOptionType => {
          return { label: model.color.name, code: model.color.code, code2: model.color.code2, isLast: index == arr.length - 1, index }
        })}
    />
  )
}

export { ColorSelect }