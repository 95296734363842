import React from "react";
import { Divisor } from "../divisor";
import { ColorOptionType, SizeOptionType } from '../../../types/option'
import { components, SingleValueProps } from "react-select";
import { CircleColor } from "../circle_color";

const ColorSingleValue = ({
  children,
  ...props
}: SingleValueProps<ColorOptionType>) => (
  <components.SingleValue {...props}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CircleColor color1={props.data.code} color2={props.data.code2} className="product-info-color" />
      {/* <div style={{ backgroundColor: props.data.code }} className="product-info-color" /> */}
      {props.data.label}
    </div>
  </components.SingleValue>
)


const SizeSigleValue = ({
  children,
  ...props
}: SingleValueProps<ColorOptionType>) => (
  <components.SingleValue {...props}>
    {children}
  </components.SingleValue>
)

export { ColorSingleValue, SizeSigleValue }