import React from "react";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';

import info_size from '../../assets/icon/info.png'

function SizeGuide(props: {
  guides: string[],
  sizeName: string,
}) {
  return (
    <Popup trigger={
      <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <u className="product-info-details-title" style={{ marginRight: '2px', color: "#cc4ba4", fontSize: '12px' }}>Guida</u>
        <img src={info_size} style={{ height: '11px' }} />
      </div>
    } modal nested>
      <div className="modal-title">
        Taglia <span style={{ fontWeight: 500 }}>{props.sizeName}</span>:
      </div>
      <ul>
        {props.guides.map(g =>
          <li className="modal-list-element">
            {g}
          </li>
        )}
      </ul>
    </Popup>
  )
}

export { SizeGuide }