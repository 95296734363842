import React from "react";
import { SummaryCartProductType } from "../../../types";
import { Summary } from "../../components/cart";

function SummaryMain(props: {
  cartItems: SummaryCartProductType[],
  total: number,
  subTotal: number,
  delivery: number,
}) {
  return (
    <Summary
      items={props.cartItems}
      total={props.total}
      subTotal={props.subTotal}
      delivery={props.delivery}
    />
  )
}

export { SummaryMain }