import React from 'react';
import { useNavigate } from 'react-router-dom';

function GoToPayment(props: any) {

  const navigate = useNavigate()

  let handleClick = () => {
    props.onClick && props.onClick()
  }

  return (
    <div className="no-select fucsia-button gotopayment-button" onClick={handleClick}>
      Procedi al pagamento
    </div>
  )
}

export { GoToPayment }