import React from "react";
import { CookieCartType, SummaryCartProductType } from "../../../types";
import { Divisor } from "../divisor";
import { SummaryElement } from "./summary_element";
import { SummaryVoice } from "./summary_voice";

function CheckoutSummary(props: {
  items: SummaryCartProductType[],
  subTotal: number,
  delivery: number,
  total: number,
}) {

  return (
    <div className="checkout-summary-container">
      {props.items.map((value, index: number) =>
        <SummaryElement item={value} key={index} />
      )}
      <Divisor />
      <SummaryVoice name="Subtotale" number_value={props.subTotal} className="checkout-summary-subtotal" />
      <SummaryVoice name="Spedizione" number_value={props.delivery} className="checkout-summary-delivery" />
      <Divisor />
      <SummaryVoice name="Totale" number_value={props.total} className="checkout-summary-total" />
    </div>
  )

}

export { CheckoutSummary }