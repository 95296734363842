import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { capPattern, emailPattern, phonePattern } from "../../constants/form/form_patterns";
import { CheckoutInput } from "./checkout_input";
import { FormInputsType } from '../../../types/form'

const requiredMessage = "Questo campo è necessario"

function CheckoutForm(props: any) {

  const { register, handleSubmit, formState: { errors } } = useForm<FormInputsType>();
  const onSubmit: SubmitHandler<FormInputsType> = (data: FormInputsType) => {
    props.onSubmit && props.onSubmit(data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-container">

      <div className="form-section-title without-top">Recapiti</div>
      <div className="block-input-line">
        <CheckoutInput
          inputProps={
            {
              ...register('email',
                {
                  required: requiredMessage,
                  pattern: {
                    value: emailPattern,
                    message: 'Inserisci un indirizzo email valido'
                  }
                }
              ),
              defaultValue: props.checkoutState?.customer?.email,
              placeholder: "Email",
              className: "checkout-input"
            }
          }

          field={errors.email}
        />
      </div>

      <div className="form-section-title">Indirizzo di spedizione</div>
      <div className="block-input-line">
        <CheckoutInput
          inputProps={
            {
              ...register('first_name', { required: requiredMessage }),
              className: "checkout-input",
              defaultValue: props.checkoutState.customer?.first_name,
              placeholder: "Nome",
            }
          }
          classNameContainer="left"
          field={errors.first_name}
        />

        <CheckoutInput
          inputProps={
            {
              ...register('last_name', { required: requiredMessage }),
              placeholder: "Cognome",
              className: "checkout-input",
              defaultValue: props.checkoutState.customer?.last_name,
            }
          }
          classNameContainer="right"
          field={errors.last_name} />

      </div>
      <div className="block-input-line">
        <CheckoutInput
          inputProps={
            {
              ...register('address', { required: requiredMessage }),
              className: "checkout-input",
              defaultValue: props.checkoutState.customer?.address,
              placeholder: "Indirizzo",
            }
          }
          id="input-address"
          classNameContainer="left"
          field={errors.address} />

        <CheckoutInput
          inputProps={
            {
              ...register('civic_number', { required: requiredMessage }),
              className: "checkout-input",
              defaultValue: props.checkoutState.customer?.civic_number,
              placeholder: "Numero civico",
            }
          }
          classNameContainer='right'
          field={errors.civic_number} />
      </div>
      <div className="block-input-line">
        <CheckoutInput
          inputProps={
            {
              ...register('zipcode',
                {
                  required: requiredMessage,
                  pattern: {
                    value: capPattern,
                    message: 'Inserisci un CAP valido'
                  }
                }
              ),
              className: "checkout-input",
              defaultValue: props.checkoutState.customer?.zipcode,
              placeholder: "CAP",
            }
          }
          classNameContainer="left"
          field={errors.zipcode} />
        <CheckoutInput
          inputProps={
            {
              ...register('city', { required: requiredMessage }),
              className: "checkout-input",
              defaultValue: props.checkoutState.customer?.city,
              placeholder: "Città",
            }
          }
          classNameContainer="middle"
          field={errors.city} />
        <CheckoutInput
          inputProps={
            {
              ...register('region', { required: requiredMessage }),
              className: "checkout-input",
              defaultValue: props.checkoutState.customer?.region,
              placeholder: "Provincia",
            }
          }
          classNameContainer="right"
          field={errors.region} />
      </div>
      <div className="block-input-line">
        <CheckoutInput
          inputProps={
            {
              ...register('phone', {
                pattern: {
                  value: phonePattern,
                  message: 'Inserisci un numero di telefono valido'
                }
              }),
              className: "checkout-input",
              defaultValue: props.checkoutState.customer?.phone,
              placeholder: "Telefono",
            }
          }

          field={errors.phone} />
      </div>

      <input type="submit" className="checkout-next-step" value="Metodo di pagamento" />
    </form>
  )
}

export { CheckoutForm }