import React from "react";
import { useParams } from "react-router-dom";

const withRouter = (Wrapped: any) => (props: any) => {
  const params = useParams();

  return (
    <Wrapped
      {...props}
      params={params}
    // etc...
    />
  );
};

export { withRouter }