import React, { useState } from "react";
import { ShopProductsContainer } from "./shop_products_container";

function ForHomePage() {

  return (
    <ShopProductsContainer
      type={'forhome'}
      categoryName="Per la casa"

    />
  )
}

export { ForHomePage }