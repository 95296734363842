import React, { useState } from "react";
import { ShopProductsContainer } from "./shop_products_container";

function AccessoriesPage() {

  return (
    <ShopProductsContainer
      type={'accessories'}
      categoryName="Accessori"

    />
  )
}

export { AccessoriesPage }