import React, { useEffect } from 'react';
import { CandorlanaePage } from '../components';
import '../styles/notfound.scss'

function NotFound() {

  console.log('NOT FOUND')
  return (
    <CandorlanaePage>
      <div className="notfound-body">
        <h2>Pagina non trovata</h2>
        <p>Sembra che la pagina a cui stai cercando di accedere non esiste. Controlla l'indirizzo e riprova</p>
      </div>
    </CandorlanaePage>
  );
}

export default NotFound;
