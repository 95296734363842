import React, { useContext } from "react"
import { useEffect } from "react"
import { NavLink } from "react-router-dom"
import { createSolutionBuilderWithWatch } from "typescript"
import { CartContext } from "../../helper/context/cart_context"
import cart from '../../assets/icon/bag.png'

function CartNavElement(props: any) {
  const className = props.className || ""

  return (
    <NavLink className={className} to={props.to} style={{ position: "relative", display: 'flex', alignItems: "center" }}>
      carrello
      <CartIcon
        to={props.toCart}
        cartIcon={props.cartIcon}
        colorCartNotify={props.colorCartNotify}
        colorCartNotifyBorder={props.colorCartNotifyBorder}
      />
    </NavLink>
  )
}

function CartIcon(props: any) {
  const [state, setState] = useContext(CartContext)
  return (
    <div style={{ position: "relative", display: 'flex', alignItems: "center" }} onClick={props.onClick}>
      <img id="navbar-icon" src={cart} style={{ height: '100%' }} />
      {
        state.length > 0
        &&
        <div
          className="cart-notify"
        >
          {
            state
              .map((elem: any) => elem.qty)
              .reduce((x: number, y: number) => x + y)
          }
        </div>
      }
    </div>
  )
}

export { CartNavElement, CartIcon }