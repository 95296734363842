import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReviewInfo } from "../../components/checkout/review/review_info";
import { handlePayment, stripePromise } from "../../helper/handlers/payment_handler";
import '../../styles/checkout.scss'
import { CartContext } from "../../helper/context/cart_context";
import { v4 as uuidv4 } from 'uuid'
import { LoadingComponent } from "../../components/loading_component";
import { cartCookie } from "../../helper/cookie_helper";
import { Elements, useStripe } from "@stripe/react-stripe-js";

const idempotencyKey = uuidv4()

function CheckoutReview(props: any) {

  return (
    <Elements stripe={stripePromise}>
      <ReviewInfo customer={props.checkoutState.customer} paymentCard={props.checkoutState.paymentCard} />
      <PayButton checkoutState={props.checkoutState} />
    </Elements>)
}

function PayButton(props: any) {
  const stripe = useStripe()
  const [isLoading, setLoading] = useState(false)
  const [cartState, setCartState] = useContext(CartContext)

  const navigate = useNavigate()

  const beforeUnloadListener = (event: any) => {
    event.preventDefault();
    return event.returnValue = "Are you sure you want to exit?";
  };

  useEffect(() => {
    console.log(idempotencyKey)
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
    }
  })

  useEffect(() => {
    if (isLoading) {
      window.addEventListener('beforeunload', beforeUnloadListener, { capture: true });
    }
  }, [isLoading])

  const handleClick = async () => {
    if (!stripe) return;
    console.log("handleClick", props.checkoutState)
    setLoading(true);


    handlePayment(
      stripe,
      props.checkoutState.paymentMethodId,
      idempotencyKey,
      props.checkoutState.customer,
      props.checkoutState.order,
      (data) => {
        setLoading(false)
        setCartState(cartCookie())
        navigate(`/accepted?order=${data.order_id}`, {
          replace: true
        })
      },
      () => {
        setLoading(false)
        navigate('/rejected')
      }
    )
  }

  return (
    <div className={`checkout-next-step ${isLoading ? 'disabled' : ''}`} onClick={() => !isLoading && handleClick()}>
      <LoadingComponent
        isLoading={isLoading}
        main={<>Paga</>}
        loading={
          <div className="spinner" />
        } />
    </div>
  )
}

export { CheckoutReview }