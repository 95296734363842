import React from "react";

function Margined(props: any) {
  return (
    <div id="">
      {props.children}
    </div>
  )
}

export { Margined }