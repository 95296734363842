import React from "react";
import { FormInputsType } from "../../../../types";
import { CheckoutReviewType, PaymentCardType } from "../../../../types/checkout";
import { displayCardNumber, displayPhoneNumber } from "../../../helper/displayer";
import { Divisor } from "../../divisor";

function ReviewInfo(props: {
  customer: FormInputsType,
  paymentCard: PaymentCardType
}) {
  return (
    <div className="checkout-review-info-container">
      <div className="checkout-review-info-category-container">
        <div className="checkout-review-info-category-title">
          Contatto
        </div>
        <div className="checkout-review-info-category-values">
          <div className="checkout-review-info-category-value" style={{ marginBottom: 0 }}>
            {props.customer?.email}
          </div>
        </div>
      </div>
      <Divisor />
      <div className="checkout-review-info-category-container">
        <div className="checkout-review-info-category-title">
          Spedizione
        </div>
        <div className="checkout-review-info-category-values">
          <div className="checkout-review-info-category-value">
            {props.customer?.first_name} {props.customer?.last_name}
          </div>
          <div className="checkout-review-info-category-value">
            {props.customer?.address} {props.customer?.civic_number}
          </div>
          <div className="checkout-review-info-category-value">
            {props.customer?.city}, {props.customer?.zipcode} - {props.customer?.region}
          </div>
          <div className="checkout-review-info-category-value" style={{ marginBottom: 0 }}>
            {displayPhoneNumber(props.customer?.phone)}
          </div>
        </div>
      </div>
      <Divisor />
      <div className="checkout-review-info-category-container">
        <div className="checkout-review-info-category-title">
          Pagamento
        </div>
        <div className="checkout-review-info-category-values">
          <div className="checkout-review-info-category-value">
            {displayCardNumber(props.paymentCard.lastFour)}
          </div>
          <div className="checkout-review-info-category-value" style={{ marginBottom: 0 }}>
            {props.paymentCard.expirationMonth}/{props.paymentCard.expirationYear}
          </div>
        </div>
      </div>
    </div>
  )
}

export { ReviewInfo }