import React from "react";
import { SummaryElement } from "./summary_element";

function Summary(props: any) {
  return (
    <div className="summary-container">
      <div className="summary-title">
        Riepilogo
      </div>
      <div className="summary-body">
        {props.items.map((item: any, index: number) => <SummaryElement key={index} name={item.name} price={item.price} qty={item.qty} />)}
        <SummaryElement style={{ marginTop: '45px' }} name="Spedizione" price={props.delivery} />
        <div style={{ height: '.5px', width: '100%', backgroundColor: 'rgba(101, 101, 101, 0.17)' }} />
        <SummaryElement style={{ marginTop: '25px', marginBottom: '0' }} name="Totale" price={props.total} total />
      </div>
    </div>
  )
}

export { Summary }