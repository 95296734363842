import React from "react";

function AcceptedPaymentMain(props: {
  order_id: string,
  handleClick: React.MouseEventHandler<HTMLDivElement> | undefined
}) {

  return (
    <>
      <h2>Grazie, ordine effettuato!</h2>
      <p>Ti abbiamo inviato una e-mail all'indirizzo fornito in fase di pagamento di conferma per l'ordine <b>{props.order_id}</b>! Appena avremo il tracking della spedizione
        sarà nostra premura inviarti una e-mail con il link per seguire passo per passo il tuo pacco.</p>
      <p className="accepted-thanks">Grazie per aver scelto candorlanae.</p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="no-select checkout-next-step" onClick={props.handleClick}>
          Torna alla home
        </div>
      </div>
    </>
  )
}

export { AcceptedPaymentMain }