import React, { useRef, useState } from "react";

function Collapsible(props: {
  children: React.ReactChild,
  btn: (isExpanded: boolean) => boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined,
  duration: string,
  onClick?: () => any,
}) {

  const ref = useRef<HTMLDivElement>(null)
  const [isExpanded, setExpanded] = useState(false)

  return (
    <div onClick={() => {
      if (!ref) return
      setExpanded(!isExpanded)
      props.onClick && props.onClick();
    }}>
      {props.btn(isExpanded)}
      <div ref={ref} style={{
        maxHeight: isExpanded ? ref.current?.scrollHeight : 0,
        overflow: 'hidden',
        transition: `max-height ${props.duration}`
      }}>
        {props.children}
      </div>
    </div>
  )
}

export { Collapsible }