import Cookies from "js-cookie";
import { ColorType, CookieCartType } from "../../types";
import { cookieAttributes } from "../constants/cookie_attributes";

export function cartCookie(): CookieCartType[] {
  let cookie = Cookies.get('cart')

  if (!cookie || cookie?.length == 0) {
    return [];
  }

  return JSON.parse(Cookies.get('cart')!)
}

export function removeFromCookie(_id: String, size: String, all: boolean = false) {
  let cartCookie_ = cartCookie();
  // console.log(cartCookie_);
  // console.log(_id)

  let index = cartCookie_.findIndex((item) => item._id == _id && item.size == size);

  if (index != -1) {
    if (all)
      cartCookie_[index].qty = 0
    else
      cartCookie_[index].qty -= 1
  }

  cartCookie_ = cartCookie_.filter((item: any) => item.qty > 0)
  // console.log(cartCookie_)

  Cookies.set('cart', JSON.stringify(cartCookie_), cookieAttributes)//se cartCookie_ è vuoto, cookies.cart è uguale alla stringa vuota (ovvero "")
}

export function removeAllCookie() {
  Cookies.set('cart', '', cookieAttributes)
}

export function getProduct(_id: string, color: ColorType) {
  let cartCookie_ = cartCookie();

  let index = cartCookie_.findIndex(item => item._id == _id && item.color.code == color.code)
  if (index == -1)
    return null
  //console.log("cartCookie().at(index)", cartCookie().at(index))
  return cartCookie_[index]
}