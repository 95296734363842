import React from "react";
import { displayPrice } from "../../helper/displayer";

function SummaryElement(props: any) {

  const isTotal = props.total || false

  return (
    <div style={props.style} className="summary-body-element">
      <div className={`summary-body-element-name ${isTotal ? "total" : ''}`}>
        {props.name} {props.qty > 1 && `(x${props.qty})`}
      </div>
      <div className={`summary-body-element-${isTotal ? "total" : "price"}`}>
        {
          //if 'props.qty != undefined' id for product's count, else is 'spedizione' or 'total'
          props.qty != undefined ?
            displayPrice(props.price * props.qty)
            :
            displayPrice(props.price)
        }
      </div>
    </div>
  )
}

export { SummaryElement }