import React from 'react';
import { ErrorMsgProps } from '../../../types/form'

function ErrorMsg(props: ErrorMsgProps) {
  const field = props.field;

  return (
    <div className="error-msg" style={{ marginTop: field?.type ? '3px' : 0, marginLeft: '3px' }}>
      {field?.type != undefined && field?.message}
    </div>
  )
}

export { ErrorMsg } 