import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement,
  CardCvcElement,
  IbanElement,
  CardNumberElement,
  CardExpiryElement
} from '@stripe/react-stripe-js'
import { useNavigate } from 'react-router-dom';
import { CheckoutReview } from '../../pages/checkout';
import { createPaymentMethod, handlePayment } from '../../helper/handlers/payment_handler';
import { LoadingComponent } from '../loading_component';
import { CircularProgress } from '@mui/material';

function PaymentForm(props: any) {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);


  let handleClick = async () => {
    if (!stripe || !elements) return
    setIsLoading(true)
    console.log(props.checkoutState)

    const { address, city, region, zipcode } = props.checkoutState.customer
    const { first_name, last_name, phone } = props.checkoutState.customer

    stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement)!,
      billing_details: {
        address: {
          city,
          line1: address,
          postal_code: zipcode,
          state: region
        },
        email: props.checkoutState.customer.email,
        name: first_name + " " + last_name,
        phone
      }
    }).then(result => {
      if (result.error)
        return Promise.reject(result.error)

      props.setCheckoutState({
        ...props.checkoutState,
        paymentMethodId: result.paymentMethod?.id,
        paymentCard: {
          lastFour: result.paymentMethod?.card?.last4,
          expirationMonth: result.paymentMethod?.card?.exp_month,
          expirationYear: result.paymentMethod?.card?.exp_year,
          brand: result.paymentMethod?.card?.brand
        }
      })

      props.onSubmit()
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <>
      <form className="form-container">
        <div className="form-section-title without-top">Carta di credito</div>

        <div className="block-input-line">
          <div className="checkout-input-container">

            <CardNumberElement
              options={{
                placeholder: "Numero carta",
                style: {
                  base: {
                    fontSize: '15px',
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: 'Inconsolata',
                    fontWeight: 400,
                    "::placeholder": {
                      color: "#aab7c4"
                    }
                  },
                  invalid: {
                    color: "#9e2146"
                  }
                },
                iconStyle: 'solid',
                showIcon: true
              }
              }
            />
          </div>
        </div>
        <div className="block-input-line">
          <div className="checkout-input-container left">

            <CardExpiryElement
              options={{
                style: {
                  base: {
                    fontSize: '14px',
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: 'Inconsolata',
                    fontWeight: 400,
                    "::placeholder": {
                      color: "#aab7c4"
                    }
                  },
                  invalid: {
                    color: "#9e2146"
                  }
                }
              }
              }
              onReady={() => {
                console.log("CardNumberElement [ready]");
              }}
              onChange={event => {
                console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                console.log("CardNumberElement [focus]");
              }}
            />
          </div>
          <div className="checkout-input-container right">

            <CardCvcElement
              options={{
                style: {
                  base: {
                    fontSize: '14px',
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: 'Inconsolata',
                    fontWeight: 400,
                    "::placeholder": {
                      color: "#aab7c4"
                    }
                  },
                  invalid: {
                    color: "#9e2146"
                  }
                }
              }
              }
              onReady={() => {
                console.log("CardNumberElement [ready]");
              }}
              onChange={event => {
                console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                console.log("CardNumberElement [focus]");
              }}
            />
          </div>
        </div>
      </form>
      <div className={`checkout-next-step no-select ${isLoading ? 'disabled' : ''}`} onClick={handleClick}>
        <LoadingComponent
          isLoading={isLoading}
          main={<>Rivedi l'ordine</>}
          loading={
            <div className="spinner" />
          }
        />
      </div>
    </>
  )
}

export { PaymentForm }