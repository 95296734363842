import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ProductType } from '../../../../types'
import '../../../styles/home.scss'
import arrow_forward from '../../../assets/icon/arrow_forward.svg'
import arrow_back from '../../../assets/icon/arrow_back.svg'
import scroll_icon from '../../../assets/icon/scroll-icon.svg'
import { Margined } from '../../margined_component';
import { LoadingComponent } from '../../loading_component';
import { AdvicesMain } from '../../../main_content/home/advices_main';
import { AdvicesLoading } from '../../../loading/home/advices_loading';

function Advices(props: any) {

  const [items, setItems] = useState<ProductType[]>([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    axios.get('/api/advices').then((response) => {

      let itemsAdvices = response.data
      setItems(itemsAdvices)
      setLoading(false)
    })
  }, [])


  return (
    <>
      <div className="home-category-container">
        <div className="home-category-title">
          Scopri le novità
        </div>
        <img
          className='home-category-scroll-icon'
          src={scroll_icon}
        />
      </div>
      <div className="advices" >
        <LoadingComponent
          isLoading={isLoading}
          loading={<AdvicesLoading />}
          main={<AdvicesMain items={items} />}
        />
      </div>
    </>
  )
}

export { Advices }