import React, { useState } from "react";
import { ImgSizeType } from '../../types/image'
import { convertedImageName } from "../helper/replacer_img_name";
import { AspectRatioComponent } from "./aspect_ratio_component";
import { Skeleton } from "./skeleton";

function CandorlanaeImage(props: {
  staticImg?: string,
  externalImg?: {
    src: string,
    size: ImgSizeType
  }
  src2?: string,
  percentage: string,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
  containerClassName?: string,
  imgClassName?: string,
  imgStyle?: object,
  containerStyle?: object,
  skeletonStyle?: any,
  onLoad?: () => void,
}) {
  const [loading, setLoading] = useState(true)
  return (
    <div
      className={props.containerClassName}
      style={{ position: 'relative', ...props.containerStyle, cursor: 'pointer', overflow: 'hidden' }}
      onClick={props.onClick}
    >
      {loading &&
        <Skeleton
          percentage={props.percentage}
          style={{
            height: '100%', width: '100%',

            ...props.skeletonStyle
          }}
        />}
      <AspectRatioComponent percentage={loading ? '0' : props.percentage}>
        <img
          src={props.staticImg ?? `${process.env.REACT_APP_API_URL}/file/${convertedImageName(props.externalImg!.src, props.externalImg!.size)}`}
          style={{
            display: loading ? 'none' : '',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            height: loading ? 0 : '100%',
            width: '100%',
            objectFit: 'cover',
            ...props.imgStyle
          }}
          className={props.imgClassName}

          onLoad={() => {
            props.onLoad && props.onLoad()
            setLoading(false)
          }} />
        {props.src2 && <img
          src={`${process.env.REACT_APP_API_URL}/file/${convertedImageName(props.src2, props.externalImg!.size)}`}
          className={"image-hover " + props.imgClassName ?? ''}
          style={{
            display: loading ? 'none' : '',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            height: loading ? 0 : '100%',
            width: '100%',
            objectFit: 'cover',
            ...props.imgStyle
          }}
          onLoad={() => {
            props.onLoad && props.onLoad()
            setLoading(false)
          }} />}
      </AspectRatioComponent>
    </div>
  )
}

export { CandorlanaeImage }