import React from "react";

function CircleColor(props: {
  color1: string,
  color2?: string,
  style?: any,
  className?: string
}) {

  if (props.color2)
    return (
      <div style={{ ...props.style, display: 'flex' }} className={props.className}>
        <div
          style={{
            height: '10px',
            width: '5px',
            borderRadius: '10px 0 0 10px',
            backgroundColor: props.color1
          }} />
        <div
          style={{
            height: '10px',
            width: '5px',
            borderRadius: '0 10px 10px 0',
            backgroundColor: props.color2
          }} />
      </div>
    )

  return (
    <div
      className={props.className}
      style={{
        height: '10px',
        width: '10px',
        borderRadius: '10px',
        backgroundColor: props.color1,
        ...props.style
      }} />
  )
}

export { CircleColor }