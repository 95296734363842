import React from 'react';
import { Skeleton } from '../components/skeleton';

function AcceptedPaymentLoading() {
  return (
    <>
      <h2>
        <Skeleton style={{ width: '40%', height: '40px' }} />
      </h2>

      <Skeleton style={{ height: '18px', marginBottom: '5px' }} />
      <Skeleton style={{ height: '18px', marginBottom: '5px' }} />
      <Skeleton style={{ height: '18px', }} />
      {/* <h2>Grazie, ordine effettuato!</h2>
      <p>Ti abbiamo inviato una e-mail all'indirizzo fornito in fase di pagamento di conferma per l'ordine <b>{props.handleClick}</b>! Appena avremo il tracking della spedizione
        sarà nostra premura inviarti una e-mail con il link per seguire passo per passo il tuo pacco.</p> */}
      <p className="accepted-thanks">
        <Skeleton style={{ width: '45%', height: '22px' }} />
      </p>
    </>
  )
}

export { AcceptedPaymentLoading }