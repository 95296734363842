import { CookieCartType, SummaryCartProductType } from "../../types";

// soglia oltre la quale la consegna è gratuita (in centesimi)
const FREE_DELIVERY_THRESHOLD = 18000

export function getSubTotal(products: SummaryCartProductType[]): number {
  if (products.length == 0)
    return 0;

  if (products.length == 1)
    return products[0].price! * products[0].qty

  return (
    products
      .map((item) => item.price! * item.qty)
      .reduce((sum, item) => sum + item)
  )
}

export function getDelivery(products: SummaryCartProductType[]): number {
  if (products.length == 0)
    return 0;

  if (getSubTotal(products) > FREE_DELIVERY_THRESHOLD) {
    return 0;
  }

  if (products.length == 1)
    return products[0].delivery_price

  return (
    products
      .map(product => product.delivery_price)
      .reduce((x: number, y: number) => Math.max(x, y))
  )
}

export function getTotal(products: SummaryCartProductType[]): number {
  return getSubTotal(products) + getDelivery(products)
}