import React from "react";

function AspectRatioComponent(props: {
  percentage: string,
  children: any
}) {
  return (
    <div style={{ paddingBottom: props.percentage }}>
      {props.children}
    </div>
  )
}

export { AspectRatioComponent } 