import React, { useState } from "react";
import { ErrorMsg } from "./error_msg";

import '../../styles/form.scss'

const CheckoutInput =
  React.forwardRef<
    HTMLDivElement,
    ReturnType<any>
  >((props, ref) => (<div ref={ref} id={props.id} className={`checkout-input-container ${props.classNameContainer ?? ''}`}>
    <input
      {...props.inputProps}
    />
    {<ErrorMsg field={props.field} />}
  </div>
  ))

export { CheckoutInput }