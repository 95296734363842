import React from "react";
import { Divisor } from "../divisor";
import { ColorOptionType, SizeOptionType } from '../../../types/option'
import { CircleColor } from "../circle_color";

const ColorOption = ({ data, innerProps }: { data: ColorOptionType, innerProps: any }) => (
  <div {...innerProps}>
    <div className="product-info-color-option-container">
      <CircleColor color1={data.code} color2={data.code2} className="product-info-color" />
      {data.label}
    </div>
    {!data.isLast && <Divisor style={{ margin: 0, marginLeft: 'auto', width: '95%', boxSizing: 'content-box' }} />}
  </div>
)


const SizeOption = ({ data, innerProps }: { data: SizeOptionType, innerProps: any }) => (
  <div {...innerProps}>
    <div className="product-info-size-option-container">
      {data.label}
    </div>
    {!data.isLast && <Divisor style={{ margin: 0, marginLeft: 'auto', width: '95%', boxSizing: 'content-box' }} />}
  </div>
)

export { ColorOption, SizeOption }