import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { NavBar } from './navbar';
import '../styles/candorlanae_page.scss'
import '../styles/skeleton.scss'
import { Footer } from './footer';
import { Popup } from './popup';
import { SlidingPanel } from './sliding_panel';
import { useOnLocationChange } from '../helper/hooks/use_on_location_change';
import { useElementSize } from 'usehooks-ts'

function CandorlanaePage(props: any) {

  const [panelVisible, setPanelVisible] = useState(false)
  const [navbarRef, { width, height }] = useElementSize()

  useOnLocationChange((_location) => {
    window.scrollTo(0, 0)
  })


  return (
    <div id="main">
      <SlidingPanel
        status={panelVisible}
        handleClose={() => setPanelVisible(false)}
      />
      <div onClick={() => setPanelVisible(false)} style={{ position: 'relative' }}>
        <NavBar
          ref={navbarRef}
          handleClickHamburger={(e: any) => { e.stopPropagation(); setPanelVisible(true) }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', minHeight: '100vh', paddingTop: height }}>
          {props.children}
          <Footer />
        </div>
        <Popup />
      </div>
    </div>
  )
}

export { CandorlanaePage }