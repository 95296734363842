import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PopupContext } from '../helper/context/popup_context';
import { PopupContextType } from '../../types/popup'

const TIME_TO_HIDE = 3000

function Popup(props: any) {

  const { popupState, setPopupMessage } = useContext(PopupContext) as PopupContextType

  useEffect(() => {
    if (popupState.message != "")
      setTimeout(() => {
        setPopupMessage("")
      }, TIME_TO_HIDE)
  }, [popupState.message])

  return (
    <div
      className={`popup ${popupState.message != "" ? 'popup-visible' : ''}`}
      onClick={popupState.action}
    >
      {popupState.message}
    </div>
  )
}

export { Popup }