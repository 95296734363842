import React from "react";

function QuestionMain(props: {
  question: string,
  handleClick: (arg0: number) => void,
  isVisible: boolean,
  animation: any,
  onAnimationEnd: React.AnimationEventHandler<HTMLDivElement> | undefined
}) {
  return (
    <>
      <div className="question">
        {props.question}
      </div>

      <div className="answers">
        {props.isVisible ?
          <div
            className={`btns ${props.animation ? 'hide' : ''}`}
            onAnimationEnd={props.onAnimationEnd}
          >
            <div className="button button-white no-select" style={{ width: '140px', margin: '0 7px', marginBottom: '7px' }} onClick={() => props.handleClick(0)}>
              Certo!
            </div>
            {/* <div className="button button-white no-select" style={{ width: '140px', margin: '0 10px' }} onClick={() => props.handleClick(1)}>
              Sì
            </div> */}
            <div className="button button-white no-select" style={{ width: '140px', margin: '0 7px', marginBottom: '7px' }} onClick={() => props.handleClick(1)}>
              No..
            </div>
          </div>
          :
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <div className="answer-thanks">
              Grazie per il feedback!
            </div>
          </div>
        }
      </div>
    </>
  )
}

export { QuestionMain }