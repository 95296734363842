export function displayPrice(price: number, withDecimal = true) {

  if (price == 0)
    return "Gratuito"

  let eur = (price / 100).toFixed(withDecimal ? 2 : 0)
  return eur + " €"
}

export function displayPhoneNumber(phone: string | undefined) {
  if (phone == undefined)
    return

  let first = phone.slice(0, 3)
  let second = phone.slice(3, 6)
  let third = phone.slice(6)

  return `${first} ${second} ${third}`
}

export function displayCardNumber(lastFour: string) {
  let hidedChar = "•"

  return `${hidedChar.repeat(12)}${lastFour}`
}