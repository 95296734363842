import { height } from "@mui/system";
import React from "react";
import { AspectRatioComponent } from "../../components/aspect_ratio_component";
import { Skeleton } from "../../components/skeleton";

function ItemCardLoading(props: any) {
  return (
    <div
      className="item-container" style={{
        opacity: props.opacity
      }}>
      <Skeleton style={{ height: '100%', width: '100%', marginBottom: '14px', borderRadius: '8px' }} percentage={'129%'} />
      <div className="item-info">
        <div
          className="item-name">
          <Skeleton style={{ height: '22px', width: '80%' }} />
        </div>
        <div
          className="item-description">
          <Skeleton style={{ height: '16.5px', width: '100%', marginBottom: '4px' }} />
        </div>
        <div
          className="item-price">
          <Skeleton style={{ height: '20.5px', width: '40%' }} />
        </div>
      </div>
    </div>
  )
}

export { ItemCardLoading }