import React from "react";
import '../styles/footer.scss'
import logo from '../assets/icon/logo-scritta-grey.svg'
import facebook from '../assets/icon/facebook.png'
import instagram from '../assets/icon/instagram.png'
import { Divisor } from "./divisor";
import { FACEBOOK_URL, INSTAGRAM_URL, MAIL_URL, TOMMASO_AMADORI_URL } from "../constants/urls";

function Footer() {
  //TODO: Cambiare NavBar qui: quando viene ridotta la dimensione della pagina non deve comparire il menu
  return (
    <>
      <Divisor style={{ width: '180px', marginRight: 'auto', marginLeft: 'auto', marginTop: '60px' }} />
      <footer className="footer-container">
        <div className="footer-body">
          <div className="footer-main">
            <div className="footer-main-left">
              <img src={logo} style={{ height: '23px', marginBottom: '10px' }} />
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <a href={FACEBOOK_URL} style={{ marginRight: '15px' }}>
                  <img src={facebook} style={{ height: '22px' }} />
                </a>
                <a href={INSTAGRAM_URL}>
                  <img src={instagram} style={{ height: '22px' }} />
                </a>
              </div>
            </div>
            <div className="footer-main-right">
              {/* <div className="footer-topic">
              <div className="footer-topic-title">
                Aiuto
              </div>
              <div className="footer-topic-element">
                Rintraccia la spedizione
              </div>

            </div> */}
              <div className="footer-topic">
                <div className="footer-topic-title">
                  Contattaci
                </div>
                <div className="footer-topic-element">
                  <a href={MAIL_URL}>
                    Dubbi o feedback?
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-lastline-container">
            © {new Date().getFullYear()} Candorlanae — Sito sviluppato da <u><a href={TOMMASO_AMADORI_URL}>Tommaso Amadori</a></u>
            {/* <div className="footer-copyright">
            © 2021 Candorlanae
          </div>
          <div className="footer-developed-by">
            Sito sviluppato da <u><a href="https://www.github.com/tomgeek27">Tommaso Amadori</a></u>
          </div> */}
          </div>
        </div>
      </footer>
    </>
  )
}

export { Footer }