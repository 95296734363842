import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormInputsType } from '../../../types/form';
import { CandorlanaePage } from '../../components';
import { CheckoutForm } from '../../components/checkout/checkout_form';
import { CheckoutSummary } from '../../components/checkout/checkout_summary';
import '../../styles/checkout.scss'
import { CheckoutCustomerType, CheckoutPaymentType } from '../../../types/checkout'
import { cartCookie } from '../../helper/cookie_helper';
import { displayPrice } from '../../helper/displayer';
import { Collapsible } from '../../components/collapsible';
import { CheckoutPage } from './checkout_page';

const SUMMARY_MOBILE_ID = 'summary-mobile-id'

function CheckoutCustomerInfo(props: any) {

  let handleSubmit = (data: FormInputsType) => {
    props.setCheckoutState({
      ...props.checkoutState,
      customer: data
    })

    props.onSubmit()
  }

  return (
    <CheckoutForm
      onSubmit={handleSubmit}
      checkoutState={props.checkoutState}
    />
  )
}

export { CheckoutCustomerInfo }