import React from "react";
import { Skeleton } from "../components/skeleton";

function ProductLoading(props: any) {
  return (
    <>
      <div className="product-body-main">
        <div className="product-otherimage-body">
          <Skeleton style={{ height: '70px', width: '70px', marginBottom: '14px' }} />
          <Skeleton style={{ height: '70px', width: '70px', marginBottom: '14px', opacity: '.6' }} />
          <Skeleton style={{ height: '70px', width: '70px', opacity: '.3' }} />
        </div>
        <div className="product-slides">
          <Skeleton className="product-image-container" percentage="129%" />
        </div>

        <div className="product-info-container">
          <div className="product-info">
            <div className="product-info-title">
              <Skeleton style={{ height: '26px', width: '40%' }} />
            </div>
            <div className="product-info-price">
              <Skeleton style={{ height: '28.5px', width: '30%' }} />
            </div>
            <div className="product-info-details-container">
              <div className="product-info-color-container">
                <div className="product-info-details-title product-info-color-title">
                  Colore:
                </div>
                <div className="product-info-colors">
                  <Skeleton style={{ height: '30px', width: '100%', overflow: 'hidden' }} />
                </div>
              </div>
              <div className="product-info-size-container">
                <div className="product-info-details-title product-info-size">
                  Taglia:
                </div>
                <div className="product-info-size-list">
                  <Skeleton style={{ height: '30px', width: '100%', overflow: 'hidden' }} />
                </div>
              </div>

            </div>
            <Skeleton style={{ height: '40px', width: '100%', marginTop: '40px' }} />

            <Skeleton style={{ height: '15px', width: '100%', marginTop: '40px' }} />
            <Skeleton style={{ height: '15px', width: '100%', marginTop: '10px' }} />
            <Skeleton style={{ height: '15px', width: '100%', marginTop: '10px' }} />
          </div>
        </div>
      </div>
      <div className="product-features-body">
        <div className="product-features-container">
          <div className="product-features-title">
            <Skeleton style={{ height: '23px', width: '30%' }} />
          </div>
          <div className="product-features-description">
            <Skeleton className="product-features-description-element" style={{ height: '15px', width: '45%' }} />
            <Skeleton className="product-features-description-element" style={{ height: '15px', width: '45%', marginTop: '10px' }} />
            <Skeleton className="product-features-description-element" style={{ height: '15px', width: '45%', marginTop: '10px' }} />
          </div>
        </div>
        <div className="product-features-container">
          <div className="product-features-title">
            <Skeleton style={{ height: '23px', width: '30%' }} />
          </div>
          <div className="product-features-description">
            <Skeleton className="product-features-description-element" style={{ height: '15px', width: '45%' }} />
            <Skeleton className="product-features-description-element" style={{ height: '15px', width: '45%', marginTop: '10px' }} />
            <Skeleton className="product-features-description-element" style={{ height: '15px', width: '45%', marginTop: '10px' }} />
          </div>
        </div>
        <div className="product-features-container ">
          <div className="product-features-title">
            <Skeleton style={{ height: '23px', width: '30%' }} />
          </div>
          <div className="product-features-description">
            <Skeleton className="product-features-description-element" style={{ height: '15px', width: '45%' }} />
            <Skeleton className="product-features-description-element" style={{ height: '15px', width: '45%', marginTop: '10px' }} />            </div>
          <div className="product-features-title margined">
            <Skeleton style={{ height: '23px', width: '30%' }} />
          </div>
          <div className="product-features-description">
            <Skeleton className="product-features-description-element" style={{ height: '15px', width: '45%' }} />
            <Skeleton className="product-features-description-element" style={{ height: '15px', width: '45%', marginTop: '10px' }} />

          </div>
        </div>

      </div>
    </>
  )
}

export { ProductLoading } 