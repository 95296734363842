import { ImgSizeType } from "../../types/image"

function convertedImageName(
  filename: string,
  size: ImgSizeType
) {
  if (!filename) throw "Filename non esistente" + filename

  return filename.replace('xxx', size)
}

export { convertedImageName }