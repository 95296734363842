import React from "react";
import { AspectRatioComponent } from "./aspect_ratio_component";

function Skeleton(props: {
  className?: string,
  style?: object,
  percentage?: string
}) {
  return (
    <div className={props.className} style={{ position: 'relative', overflow: 'hidden', borderRadius: '4px', ...props.style }}>
      <AspectRatioComponent percentage={props.percentage ?? '0%'}>
        <div className={`skeleton `} />
      </AspectRatioComponent>
    </div>
  )
}

export { Skeleton }