import React from 'react';
import { stripePromise } from '../../helper/handlers/payment_handler';
import {
  Elements,
} from "@stripe/react-stripe-js";
import { PaymentForm } from '../../components/checkout/payment_form';

function CheckoutPaymentInfo(props: any) {

  return (
    <Elements stripe={stripePromise} options={{
      locale: 'it', fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400&display=swap'
        }
      ]
    }}>
      <PaymentForm {...props} />
    </Elements>
  )
}

export { CheckoutPaymentInfo }