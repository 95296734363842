import React, { useState } from "react";
import next from '../../assets/icon/arrow_forward.svg'
import back from '../../assets/icon/arrow_back.svg'
import { CandorlanaeImage } from '..';
import { virtualize } from "react-swipeable-views-utils";
import SwipeableViews, { OnChangeIndexCallback } from "react-swipeable-views";

const VirtualizeSwipeableViews = virtualize(SwipeableViews)

function ImageSlider(props: {
  imgs: string[],
  imageIndex: number,
  onNext: React.MouseEventHandler<HTMLImageElement> | undefined,
  onBack: React.MouseEventHandler<HTMLImageElement> | undefined,
  onClickDot: (index: number) => void,
  onChangeImage: OnChangeIndexCallback,
}) {
  const totalImgs = props.imgs.length

  const slideRenderer = (params: { index: any; key: any; }) => {
    const { index, key } = params;

    return (

      <CandorlanaeImage
        key={key}
        externalImg={{
          src: props.imgs[Math.abs(index) % totalImgs],
          size: "normal"
        }}
        percentage={'129%'}
        containerClassName="product-image-container"
        containerStyle={{
          height: '100%'
        }}
        imgClassName="product-image-style"
      />
    )
  }

  return (
    <div className="product-slides">
      <img src={back} className="product-arrow left" onClick={props.onBack} />
      <img src={next} className="product-arrow right" onClick={props.onNext} />
      <div className="dots">
        {props.imgs.map((img, index) =>
          <div key={img} className={`dot ${index == Math.abs(props.imageIndex) % totalImgs ? 'active' : ''}`}
            onClick={() => props.onClickDot(index)}
          />
        )}
      </div>
      <VirtualizeSwipeableViews
        slideRenderer={slideRenderer}
        index={props.imageIndex}
        onChangeIndex={(index, indexLatest) => {
          props.onChangeImage(index, indexLatest)
        }}
      />
    </div>
  )
}

export { ImageSlider }