import React from 'react';
import Select, { ActionMeta } from 'react-select';

function CustomSelect(props: {
  value: any,
  defaultValue: any,
  onChange: ((newValue: any, actionMeta: ActionMeta<any>) => void) | undefined,
  options: any[],
  Option: any,
  SingleValue: any
}) {
  return (
    <Select
      className="product-info-select"
      isSearchable={false}
      defaultValue={props.defaultValue}
      value={props.value}
      onChange={props.onChange}
      components={{
        Option: props.Option,
        SingleValue: props.SingleValue
      }}
      options={props.options} />
  )
}

export { CustomSelect }