import React from "react";
import { ShopProductsContainer } from "./shop_products_container";

function ClothesPage() {

  return (
    <ShopProductsContainer
      type={'clothes'}
      categoryName="Abbigliamento"
    />
  )
}

export { ClothesPage }