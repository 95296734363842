import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Pay from './pages/pay';
import NotFound from './pages/notfound';
import Accepted from './pages/accepted_payments';
import Rejected from './pages/rejected_payments';
import { Cart, Home, Product, Shop } from './pages';
import { CartProvider } from './helper/context/cart_context';
import { PopupProvider } from './helper/context/popup_context';
import { createTheme, ThemeProvider } from '@mui/material';
import { CheckoutPage } from './pages/checkout/checkout_page';
import { ClothesPage } from './pages/shop/clothes';
import { AccessoriesPage } from './pages/shop/accessories';
import { ForHomePage } from './pages/shop/forhome';
import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.withCredentials = true;

const theme = createTheme({
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 450,
      md: 769,
      lg: 1024,
      xl: 1200
    }
  }
});

function App() {

  return (
    //ThemeProvider serve a gestire la griglia di prodotto nello shop
    <ThemeProvider theme={theme}>
      <PopupProvider>
        <CartProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/shop" element={<Shop />} />
              <Route path="/shop/clothes" element={<ClothesPage />} />
              <Route path="/shop/accessories" element={<AccessoriesPage />} />
              <Route path="/shop/home" element={<ForHomePage />} />

              <Route path="/cart" element={<Cart />} />
              <Route path="/checkout" element={<CheckoutPage order={undefined} children={undefined} />}>
                {/* <Route path="customer" element={<CheckoutCustomerDetails />} />
                <Route path="payment" element={<CheckoutPaymentDetails />} />
                <Route path="review" element={<CheckoutReview />} /> */}
              </Route>
              <Route path="/pay" element={<Pay />} />
              <Route path="/accepted" element={<Accepted />} />
              <Route path="/rejected" element={<Rejected />} />
              <Route path="/product/:id" element={<Product />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </CartProvider>
      </PopupProvider>
    </ThemeProvider>

  );
}

export default App;
