import axios from 'axios'
import React, { useCallback, useState } from 'react'
import { displayPrice } from '../helper/displayer'
import { LoadingComponent } from './loading_component'
import { Skeleton } from './skeleton'
import Cookies from 'js-cookie';

function HeaderBanner() {

  const [deliveryThreshold, setDeliveryThreshold] = useState<number>(parseInt(Cookies.get('threshold_price') ?? "0"))

  if (Cookies.get('threshold_price') == undefined) {
    console.log(`REQUEST THRESHOLD PRICE ${Cookies.get('threshold_price')}`);

    axios.get('/api/thresholdPrice')
      .then((response) => {
        setDeliveryThreshold(parseInt(response.data))
      })

    return <div />
  }


  return (
    <div className={`header-banner`}>
      Spedizione gratuita per ordini superiori a {" " + displayPrice(deliveryThreshold, false)}
      {/* {<LoadingComponent isLoading={deliveryThreshold == null} loading={<Skeleton style={{ width: '40px', marginLeft: '7px' }} />} main={" " + displayPrice(deliveryThreshold, false)} />} */}
    </div>
  )
}

export { HeaderBanner }