import React, { useEffect, useState } from 'react';
import { PopupContextType, PopupStateType } from '../../../types/popup';

export const PopupContext = React.createContext<PopupContextType | null>(null);

function PopupProvider(props: any) {

  const [popupState, setPopupState] = useState<PopupStateType>({
    message: '',
    action: () => { console.log("QUESTO è in fase inziale") }
  })

  const setPopupMessage = (message: string) => {
    setPopupState({
      ...popupState,
      message
    })
  }

  const setPopupAction = (action: () => void) => {
    setPopupState({
      message: popupState.message,
      action
    })
  }

  const setMessageAndAction = (s: PopupStateType) => {
    setPopupState(s)
  }

  return (
    <PopupContext.Provider value={{ popupState, setPopupMessage, setPopupAction, setMessageAndAction }}>
      {props.children}
    </PopupContext.Provider>
  )
}

export { PopupProvider }