import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { cartCookie } from "../cookie_helper";

export const CartContext = React.createContext<any>(undefined);

export function CartProvider(props: any) {
  const [state, setState] = useState<any>(cartCookie())

  return (
    <CartContext.Provider value={[state, setState]}>
      {props.children}
    </CartContext.Provider>
  )
}