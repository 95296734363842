import React from 'react';
import { ProductType } from '../../../types';
import { ItemCard } from '../../components/item_card';

function AdvicesMain(props: { items: ProductType[] }) {
  return (
    <>
      {props.items.map((item: any, i: number, arr) =>
        <div className={`advice-container`}>
          <ItemCard
            item={item}
          />
        </div>

        // <Advice
        //   item={item}
        //   isFirst={i == 0}
        //   isLast={i == arr.length - 1}
        // />
      )}
      <div className="dummydiv-right" />

    </>
  )
}

export { AdvicesMain }