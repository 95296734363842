import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CandorlanaePage } from '../components';

function Rejected() {

  const navigate = useNavigate()
  // const location = useLocation()
  // const [errorMessage, setErrorMessage] = useState('')
  // const state = location.state as { message: string } | undefined


  // useEffect(() => {

  //   if (!state) {
  //     navigate('/error')
  //     return;
  //   }

  //   if (state)
  //     setErrorMessage(state.message)
  // }, [])

  let handleClick = () => {
    navigate('/')
  }

  return (
    <CandorlanaePage>
      <div className="result-payment-body">
        <h2>Ooops, qualcosa è andato storto!</h2>
        <p>Ritenta il pagamento più tardi, se il problema persiste contattaci.</p>
        {/* <p>{state?.message}</p> */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="no-select checkout-next-step" onClick={handleClick}>
            Torna alla home
          </div>
        </div>
      </div>
    </CandorlanaePage>
  );
}

export default Rejected;
