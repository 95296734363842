import React from 'react';
import clothes from '../../assets/background/clothes4.jpg'
import accessories from '../../assets/background/accessories.jpg'
import home from '../../assets/background/home.jpg'
import { CandorlanaeImage } from '../image';
import { useNavigate } from 'react-router-dom';

function CategoryBox(props: {
  img: string,
  name: string,
  description: string,
  to: string,
}) {

  const navigate = useNavigate()

  const handleClick = () => {
    navigate(props.to)
  }

  return (
    <>
      <CandorlanaeImage
        percentage='139%'
        imgClassName="category-image"
        staticImg={props.img}
        containerStyle={{ borderRadius: '4px' }}
        onClick={handleClick}
      />
      {/* <img src={props.img} style={{ objectFit: 'cover', objectPosition: 'center', width: '100%', height: '500px', borderRadius: '4px' }} /> */}
      <h2 style={{ textAlign: 'center', color: '#2b2b2b', fontWeight: '600', marginBottom: '10px', fontSize: '24px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
        {props.name}
      </h2>
      <p style={{ textAlign: 'center', color: '#2b2b2b', fontWeight: '500', fontSize: '15px', marginTop: '5px', margin: '0 25px' }}>
        {props.description}
      </p>
      <div className="button button-fucsia" style={{ marginRight: 0, width: 'auto', marginTop: '30px' }} onClick={handleClick}>Scopri</div>
    </>
  )
}

function CategoryClothes() {
  return (
    <CategoryBox
      img={clothes}
      name="ABBIGLIAMENTO"
      description="Prodotti unici di cui ti innamorerai"
      to="clothes"
    />
  )
}

function CategoryAccessories() {
  return (
    <CategoryBox
      img={accessories}
      name="ACCESSORI"
      description="Accessori in stile Candorlanae"
      to="/shop/accessories"
    />
  )
}

function CategoryHome() {
  return (
    <CategoryBox
      img={home}
      name="PER LA CASA"
      description="Tutto lo stile Candorlanae per la casa"
      to="/shop/home"
    />
  )
}

export { CategoryBox, CategoryClothes, CategoryAccessories, CategoryHome } 