import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { RequestSendMailCustomerReqType } from "../../../types";
import { EmailType } from "../../../types/form";
import { emailPattern } from "../../constants/form/form_patterns";
import { useHandleCustomerRequest } from "../../helper/handlers/customer_request_handler";
import { CheckoutInput } from "../checkout";

function CustomerRequestBox(props: {
  enabled: boolean,
  ttd: string,
  _id: string,
  name: string,
  color: string,
  size: string
}) {

  const handleCustomerRequest = useHandleCustomerRequest()

  const { register, handleSubmit, formState: { errors } } = useForm<EmailType>();
  const onSubmit: SubmitHandler<EmailType> = (data) => {
    const payload: RequestSendMailCustomerReqType = {
      ...data,
      product: {
        _id: props._id,
        name: props.name,
        color: props.color,
        size: props.size
      }
    }

    handleCustomerRequest(payload);
  }

  if (props.enabled)
    return null

  return <div className="product-customer-request-container">
    <div className="product-customer-request-title">
      Prodotto terminato, ti interessa?
    </div>
    <div className="product-customer-request-description">
      Inserisci la tua email qui sotto per permetterci di comunicare con te ed esaudire il tuo desiderio
    </div>

    <div className="product-customer-request-ttd" style={{ display: 'flex' }}>
      Tempo di produzione: <div style={{ fontWeight: '600', marginLeft: '5px' }}>{props.ttd}</div>
    </div>

    <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', alignItems: 'flex-start' }}>
      <CheckoutInput
        inputProps={
          {
            ...register('email',
              {
                required: 'Inserisci un indirizzo email',
                pattern: {
                  value: emailPattern,
                  message: 'L\'indirizzo inserito non è valido'
                }
              }
            ),
            placeholder: "Email",
            className: "checkout-input"
          }
        }

        classNameContainer="product-customer-request-input-container"

        field={errors.email}
      />
      <input
        type="submit"
        className="button button-fucsia"
        style={{ flex: 1, paddingLeft: '13px', paddingRight: '13px' }}
        value="Invia"
      />
    </form>

  </div>;
}

export { CustomerRequestBox }