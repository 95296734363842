import _ from 'lodash';
import React from 'react';
import { AspectRatioComponent } from '../../components/aspect_ratio_component';
import { Skeleton } from '../../components/skeleton';
import { ItemCardLoading } from '../shop/item_card_loading';

const ADVICE_NUM = 4

function AdvicesLoading() {
  return (
    <>
      {_.times(
        ADVICE_NUM,
        (n) =>

          <div
            className={`advice-container`}
            style={{
              cursor: 'auto',
            }}>
            <ItemCardLoading />
            <Skeleton
              style={{
                marginBottom: '14px',
                borderRadius: '8px',
              }}
            />
          </div>
      )}
    </>
  )
}

export { AdvicesLoading }