import React from "react";
import { Skeleton } from "../../components/skeleton";

function QuestionLoading() {
  return (
    <>
      <div className="question">
        <Skeleton style={{ height: '22px', width: '40%' }}
        />
      </div>

      <div className="answers">
        <div className="btns">
          <Skeleton className="answer-btn" />
          <Skeleton className="answer-btn" />
          <Skeleton className="answer-btn" />
        </div>

      </div>
    </>
  )
}

export { QuestionLoading }