import React, { useEffect, useState } from 'react';
import { CandorlanaePage, CandorlanaeImage } from '../components';
import { withRouter } from '../helper/hooks/with_route_component';
import { ProductType } from '../../types'
import { LoadingComponent } from '../components/loading_component';
import { ProductMain } from '../main_content/product_main';
import { ProductLoading } from '../loading/product_loading';
import axios from 'axios';
import '../styles/product.scss'
import { useNavigate } from 'react-router-dom';

function _Product(props: any) {

  const navigate = useNavigate()

  const [product, setProduct] = useState<ProductType>()
  const [isLoading, setLoading] = useState(true)

  const { id } = props.params

  useEffect(() => {
    axios.get('/api/product', {
      params: {
        _id: id
      }
    }).then(response => {
      console.log(response.data)
      setProduct(response.data)
      setLoading(false)
    }).catch(() => {
      navigate('/error')
    })
  }, [])


  return (
    <CandorlanaePage>
      <div className="product-body">
        <LoadingComponent
          isLoading={isLoading}
          loading={<ProductLoading />}
          main={<ProductMain product={product!} />}
        />
      </div>
    </CandorlanaePage >
  )
}

export const Product = withRouter(_Product)