import React from 'react';
import { SummaryCartProductType } from '../../../types';
import { CartElement } from '../../components/cart';
import { removeFromCookie } from '../../helper/cookie_helper';

function CartItemsMain(props: {
  cartItems: SummaryCartProductType[],
  onRemove: (arg0: SummaryCartProductType) => any,
  onAdd: (arg0: SummaryCartProductType) => void
}) {
  return (
    <>
      {props.cartItems.map((item, index) =>
        <CartElement
          key={index}
          item={item}
          onRemove={() => props.onRemove(item)}
          onAdd={() => props.onAdd(item)} />
      )}
    </>
  )
}

export { CartItemsMain }