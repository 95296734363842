import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios'
import '../../styles/home.scss'
import Cookies from 'js-cookie';
import { Margined } from '../margined_component';
import { LoadingComponent } from '../loading_component';
import { QuestionMain } from '../../main_content/home/question_main';
import { QuestionLoading } from '../../loading/home/question_loading';

function Question(props: any) {

  const [question, setQuestion] = useState<any>({})
  const [animation, setAnimation] = useState(false)
  const [answered, setAnswered] = useState(false)

  useEffect(() => {
    axios.get('/api/question', { withCredentials: true }).then(response => {
      setQuestion(response.data)
    })
  }, [])

  const handleClick = (index: number) => {
    axios.post('/api/answer', {
      q: question._id,
      a: index
    }).then(console.log)

    setAnimation(true)
  }

  console.log(Object.keys(question).length > 0, Object.keys(question), question)

  return (
    <Margined>
      <div className="home-category-title" id="h2-margined">
        Consigliaci tu!
      </div>
      <div className="question-introduction">
        Il tuo consiglio per noi è molto importante ed è per questo motivo che ti chiediamo di dirci la tua per aiutarci a migliorare e produrre sempre cose nuove.
      </div>
      <LoadingComponent
        isLoading={Object.keys(question).length <= 0}
        main={
          <QuestionMain
            question={question.q}
            isVisible={!answered && Cookies.get('answer') === undefined}
            handleClick={(i) => handleClick(i)}
            onAnimationEnd={() => setAnswered(true)}
            animation={animation}
          />
        }
        loading={
          <QuestionLoading />
        }
      />

    </Margined>
  )
}

export { Question }