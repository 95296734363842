import React from 'react';
import { CandorlanaeImage, CandorlanaePage } from '../components';
import { CategoryAccessories, CategoryClothes, CategoryHome } from '../components/shop/category_box';
import clothes from '../assets/background/clothes.jpg'

function Shop() {

  return (
    <CandorlanaePage>
      <div className="shop-body">
        <div className="shop-category-container">
          <CategoryClothes />
        </div>
        <div className="shop-category-container">
          <CategoryAccessories />
        </div>
        <div className="shop-category-container">
          <CategoryHome />
        </div>
      </div>
    </CandorlanaePage>
  )

}

export { Shop }