import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from '../assets/icon/logo-scritta-full-fucsia.svg'
import hamburger from '../assets/icon/hamburger-menu-pink.svg'
import { useScrollPosition } from "../helper/hooks/use_scroll_position";
import { CartContext } from "../helper/context/cart_context";
import { CartIcon, CartNavElement } from "./navbar/cart_icon";
import { HeaderBanner } from "./header_banner";

const toHome = "/"
const toShop = "/shop"
const toCart = "/cart"

const handleClickLogo = (navigator: any) => { navigator('/') }

const NavBar = (props: {
  screenWidth?: number,
  handleClickHamburger: React.MouseEventHandler<HTMLImageElement> | undefined
}, ref: any) => {

  const navigate = useNavigate()

  const [sticky, setSticky] = useState(true)
  const [shadowed, setShadowed] = useState(false)
  const DELTA_SCROLL = 15

  useScrollPosition(
    ({ prevPos, currPos }: any) => {
      if (currPos.y < -15)
        setShadowed(true)
      else
        setShadowed(false)

      if (currPos.y > prevPos.y)
        setSticky(true)
      else if (currPos.y + DELTA_SCROLL < prevPos.y)
        setSticky(false)
    },
    [sticky]
  )

  return (
    <header ref={ref} className={`navbar-container`} style={{
      transform: sticky ? "translateY(0)" : "translateY(-100%)",
      boxShadow: shadowed ? "0px 2px 5px 0px rgb(0 0 0 / 5%)" : 'none'
    }}>
      <HeaderBanner />
      <div className="navbar-content">

        <div className="navbar-left">
          <img
            className="hamburger-icon"
            src={hamburger}
            onClick={props.handleClickHamburger}
          />
        </div>
        <img src={logo} className="navbar-logo" onClick={() => handleClickLogo(navigate)} />
        <div className="navbar-mobile">
          <CartIcon
            className="navbar-item"
            to={toCart}
            onClick={() => navigate(toCart)}
          />

        </div>
        <div className="navbar-right">
          <NavLink className={({ isActive }) => isActive ? "navbar-item active" : "navbar-item"}
            to={toHome}>home</NavLink>
          <NavLink
            className={({ isActive }) => isActive ? "navbar-item active" : "navbar-item"}
            to={toShop}>
            shop
          </NavLink>
          {/* <NavLink className="navbar-item" to="/contattaci">Contattaci</NavLink> */}
          <CartNavElement
            className="navbar-item"
            to={toCart}
          />
        </div>
      </div>
    </header>
  )
}

const WrappedNavBar = React.forwardRef<HTMLDivElement, ReturnType<any>>(NavBar)

export { WrappedNavBar as NavBar }