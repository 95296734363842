import React from "react";
import { useEffect } from "react";

function LoadingComponent(props: {
  isLoading: boolean,
  main: React.ReactNode,
  loading: React.ReactNode
}) {
  return (
    <>
      {props.isLoading ?
        props.loading
        :
        props.main
      }
    </>
  )
}

export { LoadingComponent }