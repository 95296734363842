import React from "react";
import { displayPrice } from "../../helper/displayer";

function SummaryVoice(props: any) {
  return (
    <div className={props.className}>
      {props.name}
      <div style={{ fontWeight: '600' }}>
        {displayPrice(props.number_value)}
      </div>
    </div>
  )
}

export { SummaryVoice }