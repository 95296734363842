import axios from "axios";
import { response } from "express";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CookieCartType, ProductType, SummaryCartProductType } from '../../types';
import { OrderType } from "../../types/checkout";
import { CandorlanaePage } from "../components";
import { CartElement, Summary, GoToPayment } from "../components/cart";
import { LoadingComponent } from "../components/loading_component";
import { getDelivery, getSubTotal, getTotal } from "../helper/cart_helper";
import { CartContext } from "../helper/context/cart_context";
import { PopupContext } from "../helper/context/popup_context";
import { cartCookie, removeFromCookie } from "../helper/cookie_helper";
import { useHandleAddToCart } from "../helper/handlers/cart_handler";
import { CartItemsLoading } from "../loading/cart/cart_items_loading";
import { SummaryLoading } from "../loading/cart/summary_loading";
import { CartItemsMain } from "../main_content/cart/cart_items_main";
import { SummaryMain } from "../main_content/cart/summary_main";
import '../styles/cart.scss'

import hanger from '../assets/background/hanger1.png'

function Cart(props: any) {

  const navigate = useNavigate()
  const [cartItems, setCartItems] = useState<SummaryCartProductType[]>([])
  const [prices, setPrices] = useState<{ total: number, subtotal: number, delivery: number }>({ total: 0, subtotal: 0, delivery: 0 })
  const [state, setState] = useContext(CartContext)
  const [isLoadingProducts, setLoadingProducts] = useState(true)
  const [isLoadingPrices, setLoadingPrices] = useState(true)

  const handleAddToCart = useHandleAddToCart()

  useEffect(() => {

    const items = cartCookie()

    if (items.length > 0) {
      let ids = items.map(item => item._id);
      axios.get('/api/someProducts', {
        params: {
          ids
        }
      }).then((response: any) => {
        let fullfilledItems = items.map((item) => {
          let elem = response.data.find((x: any) => x._id == item._id)
          const { description, price, delivery_price, name } = elem

          const img = elem.model_info[item.model_index].imgs[0]

          return { ...item, img, description, price, delivery_price, name }
        })

        setCartItems(fullfilledItems)
        setLoadingProducts(false)
      })
    } else {
      setCartItems([])
    }

    axios.post('/api/getCartPrices', { items })
      .then((response) => {
        setPrices(response.data)
        setLoadingPrices(false)
        console.log(response.data)
      })

  }, [state])

  let handleClick = () => {
    let order: OrderType

    order = {
      products: cartItems,
      total: getTotal(cartItems),
      subtotal: getSubTotal(cartItems),
      delivery: getDelivery(cartItems)
    }

    navigate('/checkout', { state: { order } })
  }

  return (
    <CandorlanaePage>
      <div className="cart-body">
        {cartCookie().length > 0 ?
          <>
            <h2>Il tuo carrello</h2>
            <div className="cart-body-container">
              <div className="cart-body-products">
                <LoadingComponent
                  isLoading={isLoadingProducts}
                  main={
                    <CartItemsMain
                      cartItems={cartItems}
                      onRemove={(item) => {
                        removeFromCookie(item._id!.toString(), item.size);
                        setState(cartCookie())
                      }}
                      onAdd={(item) => {
                        handleAddToCart(
                          item._id,
                          item.model_index,
                          item.size_index
                        )
                        // axios.post('/api/addToCart',
                        //   {
                        //     _id: item._id,
                        //     color: item.color,
                        //     size: item.size,
                        //     qty: 1
                        //   }, { withCredentials: true })
                        //   .then(response => handleAddRemove(response, item))
                      }}
                    />
                  }
                  loading={<CartItemsLoading n={cartCookie().length} />}
                />
              </div>
              <div style={{ height: 'auto' }}>
                <LoadingComponent
                  isLoading={isLoadingPrices || isLoadingProducts}
                  main={
                    <SummaryMain
                      cartItems={cartItems}
                      total={prices.total}
                      subTotal={prices.subtotal}
                      delivery={prices.delivery}
                    />}
                  loading={<SummaryLoading n={cartCookie().length} />}
                />

                <GoToPayment
                  onClick={isLoadingPrices || isLoadingProducts ? null : handleClick}
                />
              </div>
            </div>
          </>
          :
          <div className="no-element-in-cart" >
            <img src={hanger} width="200" />
            <div style={{ marginTop: '30px' }}>
              Il tuo carrello è vuoto
              <div style={{ fontWeight: '300', fontSize: '14px', marginTop: '15px' }}>
                Visita il nostro shop e trova quello che fa per te!
              </div>
              <div className="button-fucsia go-to-shop no-select" onClick={() => navigate('/shop')}>
                shop
              </div>
            </div>
          </div>
        }
      </div>
    </CandorlanaePage>
  )
}

export { Cart }