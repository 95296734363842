import React, { useEffect, useState } from 'react';
import { CandorlanaePage } from '../components';
import '../styles/home.scss'
import { Advices } from '../components/home/advices/advices';
import { Question } from '../components/home/question';
import welcome from '../assets/background/welcome5.jpg';
import { SearchBar } from '../components/';
import { Margined } from '../components/margined_component';
import { News } from '../components/home/news';
import { useNavigate } from 'react-router-dom';
import { AspectRatioComponent } from '../components/aspect_ratio_component';

function Home() {

  const [screenWidth, setScreenWidth] = useState(window.screen.width)
  const navigate = useNavigate()

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(screenWidth)
    })
  }, [])

  return (
    <CandorlanaePage colorNavBar={'white'}>

      <div className="welcome-container">

        <div className="welcome-bg">

          <AspectRatioComponent percentage="52%">
            <img src={welcome} className="welcome-img" />
          </AspectRatioComponent>
          <div className="welcome-msg-container-desktop">
            <h1>
              <div className="welcome-title">
                All you need <br></br>is knitting
              </div>
              <div className="welcome-subtitle">
                Pura lana e cotone, lavorati a mano. Scopri i nostri prodotti e trova quello che fa per te!
              </div>
              <div className="button button-white welcome-button no-select" onClick={() => navigate('/shop')}>
                Scopri
              </div>
            </h1>

          </div>
        </div>
        <div className="home-body welcome-msg-container-mobile">
          <h1 className="welcome-msg">
            <div className="welcome-title">
              All you need <br></br>is knitting
            </div>
            <div className="welcome-subtitle">
              Pura lana e cotone, lavorati a mano. Scopri i nostri prodotti e trova quello che fa per te!
            </div>
            <div className="button button-white welcome-button no-select" onClick={() => navigate('/shop')}>
              Scopri
            </div>
          </h1>
        </div>
        {/* <img src={welcome} style={{
          position: 'absolute',
          right: '100px',
          height: '50vh'
        }} /> */}
      </div>
      {/* <div className="home-category">
        <h2>
          Cerca
        </h2>
        <SearchBar />
      </div> */}
      <div className="home-body">
        <div className="home-category">
          <Advices />
        </div>
        <div className="home-category colored">
          <Question />
        </div>
        {/* <div className="home-category">
          <News />
        </div> */}
      </div>
      <div className="home-whoami">
        <div className="home-whoami-container">
          <div className="home-whoami-img" />
          <div style={{ display: 'flex', flexDirection: 'column', flex: 5 }}>
            <div className="home-whoami-title">
              Le artigiane Rita e Paola
            </div>
            <div className="home-whoaim-description">
              Siamo Rita e Paola e siamo la dimostrazione che anche una suocera e una nuora possono condividere un progetto dettato da una comune passione, costruire un rapporto gioioso e creativo. Entrambe abbiamo il nostro lavoro, ma nel contempo, cerchiamo di ritagliare del tempo per le nostre creazioni. Un po' così... Per provare.. Abbiamo fatto il nostro primo mercatino a novembre 2018 e ci è molto piaciuta la possibilità di metterci in gioco. Così siamo andate avanti, sempre alla ricerca di nuove idee e nuovi modelli e oggi apriamo questo spazio di vendita online. Abbiamo intorno una famiglia numerosa che ci supporta.. Chi fa le foto, chi fa da modella, chi stampa, chi carica e scarica, chi ci sprona e sostiene!
            </div>
          </div>
        </div>
      </div>

    </CandorlanaePage>
  )
}

export { Home }