import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckoutPaymentType, CheckoutStateType, OrderType } from '../../../types/checkout';
import { CandorlanaePage } from '../../components';
import { CheckoutSummary } from '../../components/checkout';
import { Collapsible } from '../../components/collapsible';
import { displayPrice } from '../../helper/displayer';
import next from '../../assets/icon/arrow_forward.svg'
import { CheckoutCustomerInfo } from './checkout_customer_info';
import { CheckoutPaymentInfo } from './checkout_payment_info';
import { CheckoutReview } from './checkout_review';

const steps = ['Carrello', 'Informazioni', 'Pagamento', 'Conferma']

function CheckoutPage(props: { order: OrderType | undefined, children: React.ReactNode, visible?: boolean }) {
  const location = useLocation();
  const state = location.state as any;

  const navigate = useNavigate()
  const [stepIndex, setStepIndex] = useState(1)
  const [checkoutState, setCheckoutState] = useState<CheckoutStateType>(state)

  const setColor = (index: number) => {
    if (stepIndex > index)
      return 'past'

    if (stepIndex === index)
      return 'active'

    return ''
  }

  const renderCheckoutStep = (index: number) => {

    const onSubmit = () => {
      window.scrollTo(0, 0)
      setStepIndex(stepIndex + 1)
    }
    switch (index) {
      case 0:
        navigate('/cart')
        break;
      case 1:
        return <CheckoutCustomerInfo setCheckoutState={setCheckoutState} checkoutState={checkoutState} onSubmit={onSubmit} />
      case 2:
        return <CheckoutPaymentInfo setCheckoutState={setCheckoutState} checkoutState={checkoutState} onSubmit={onSubmit} />
      case 3:
        return <CheckoutReview checkoutState={checkoutState} />
      default:
        navigate('/')
        break;
    }
  }

  useEffect(() => {
    console.log(checkoutState)
  }, [checkoutState])

  return (
    <CandorlanaePage>
      <div className="checkout-body">
        <h2 id="h2-margined">Checkout</h2>
        <div className="checkout-body-container">
          <div className="checkout-summary-mobile">
            <Collapsible
              duration='300ms'
              btn={(isExpanded) => {
                return (
                  <div className="checkout-summary-mobile-btn">
                    <u>
                      {isExpanded ? 'Nascondi' : 'Mostra'} riepilogo dell'ordine
                    </u>
                    <div style={{ fontWeight: '600' }}>
                      {displayPrice(checkoutState.order!.total)}
                    </div>
                  </div>
                )
              }}
            >
              <div className="checkout-summary-mobile-container">
                <div style={{ padding: '10px 0' }} >
                  <CheckoutSummary
                    total={checkoutState.order!.total}
                    subTotal={checkoutState.order!.subtotal}
                    delivery={checkoutState.order!.delivery}
                    items={checkoutState.order!.products}
                  />
                </div>
              </div>
            </Collapsible>
          </div>
          <div className="checkout-body-step-container">
            <div className="steps-container">
              {steps.map((step, index, arr) =>
                <>
                  <div
                    className={`step ${setColor(index)}`}
                    onClick={() => stepIndex > index && setStepIndex(index)}>
                    {step}
                  </div>
                  {index != arr.length - 1 && <img src={next} className="step-arrow" />}
                </>
              )}
            </div>
            {renderCheckoutStep(stepIndex)}
          </div>
          <div className="checkout-summary-normal">
            <div className="checkout-subtitle">Riepilogo dell'ordine</div>
            <CheckoutSummary
              total={checkoutState.order!.total}
              subTotal={checkoutState.order!.subtotal}
              delivery={checkoutState.order!.delivery}
              items={checkoutState.order!.products}
            />
          </div>
        </div>
      </div>
    </CandorlanaePage>)
}

export { CheckoutPage }