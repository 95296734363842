import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { RequestAddToCartType, ResponseAddToCartType } from "../../../types";
import { PopupContextType } from "../../../types/popup";
import { CartContext } from "../context/cart_context";
import { PopupContext } from "../context/popup_context";
import { cartCookie, removeFromCookie } from "../cookie_helper";

export function useHandleAddToCart() {

  const navigate = useNavigate()
  const [state, setState] = useContext(CartContext)
  const { setMessageAndAction } = useContext(PopupContext) as PopupContextType;

  return (_id: string, model_index: number, size_index: number) => {
    const requestBody: RequestAddToCartType = {
      _id,
      model_index,
      size_index
    }

    axios.post('/api/addToCart', requestBody, {})
      .then(() => {
        setMessageAndAction({
          message: "Prodotto aggiunto al carrello!",
          action: () => navigate('/cart')
        })
        setState(cartCookie())
        console.log("aggiornato state cookie", cartCookie())
      })
      .catch(() => {
        setMessageAndAction({
          message: "La quantità richiesta non è disponibile!",
          action: undefined,
        })
      });
  }
}

export function HandleRemoveFromCart(id: string, size: string, setCartState: ((arg0: any) => void)) {
  removeFromCookie(id, size, true)
  setCartState(cartCookie())
}