import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RequestCheckPaymentType } from '../../types';
import { CandorlanaePage } from '../components';
import { LoadingComponent } from '../components/loading_component';
import { AcceptedPaymentLoading } from '../loading/accepted_payment_loading';
import { AcceptedPaymentMain } from '../main_content/accepted_payment_main';
import '../styles/payment_results.scss'

function Accepted() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentExist, setPaymentExist] = useState(false)

  useEffect(() => {

    const order_id = searchParams.get("order")

    if (order_id == undefined)
      return navigate('/error')

    const checkPaymentBody: RequestCheckPaymentType = {
      order_id
    }

    axios.post('/api/checkPayment', checkPaymentBody)
      .then(() => {
        setPaymentExist(true)
      }).catch(() => {
        navigate('/error')
      })
  }, [])

  let handleClick = () => {
    navigate('/')
  }

  return (
    <CandorlanaePage>
      <div className="result-payment-body">
        <LoadingComponent
          isLoading={!paymentExist}
          main={<AcceptedPaymentMain order_id={searchParams.get("order")!} handleClick={handleClick} />}
          loading={<AcceptedPaymentLoading />} />
      </div>
    </CandorlanaePage>
  );
}

export default Accepted;
