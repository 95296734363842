import React, { useContext } from "react";
import { CandorlanaeImage } from './image'
import { useNavigate } from "react-router-dom";
import { ProductType } from "../../types";
import { displayPrice } from "../helper/displayer";
import { CircleColor } from "./circle_color";

function ItemCard(props: { item: ProductType }) {

  const navigate = useNavigate();

  let handleClick = (id: string) => {
    navigate(`/product/${id}`)
  }

  return (
    <div onClick={() => handleClick(props.item._id!.toString())}
      className="item-container">
      <CandorlanaeImage
        externalImg={{
          src: props.item.model_info[0].imgs[0],
          size: "normal"
        }}
        src2={props.item.model_info[0].imgs[1]}
        percentage='129%'
        containerStyle={{
          height: '100%', width: '100%',
          marginBottom: '14px'
        }}
        skeletonStyle={{
          borderRadius: '4px',
        }}
        imgStyle={{
          borderRadius: '4px',
        }} />
      <div className="item-info">
        <div
          className="item-name">
          {props.item.name}
        </div>
        <div
          className="item-description">
          {props.item.short_description}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div
            className="advice-price">
            {displayPrice(props.item.price)}
          </div>

          <div style={{ fontWeight: 300, fontSize: '11px', marginRight: '10px', display: 'flex', alignItems: 'center' }}>
            {/* <img src={color} width="12" /> */}
            Colori
            {props.item.model_info.slice(0, 2).map(model =>
              <CircleColor color1={model.color.code} color2={model.color.code2} style={{ marginLeft: '4px' }} />
            )}
            {props.item.model_info.length > 2 &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '8px', color: '#2b2b2b', height: '12px', width: '16px', marginLeft: '4px', borderRadius: '40px', backgroundColor: '#eeeeee' }}>
                +{props.item.model_info.length - 2}
              </div>
            }
            {/* {props.item.model_info.length} colori */}
          </div>
        </div>
      </div>
    </div>
  )
}

export { ItemCard }