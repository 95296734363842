import axios from "axios";
import React, { useEffect, useState } from "react";
import { ShopType } from '../../../types'
import { CandorlanaePage } from "../../components";
import { MIN_PRODUCT_TO_LOAD } from "../../constants/shop";
import Grid from '@mui/material/Grid';
import { ItemCardLoading } from "../../loading/shop/item_card_loading";
import { ItemCard } from "../../components/item_card";

function ShopProductsContainer(props: {
  type: ShopType,
  categoryName: string
}) {
  const [products, setProducts] = useState([])
  const [totalProducts, setTotalProducts] = useState(0)
  const [productsPaged, setProductsPaged] = useState(MIN_PRODUCT_TO_LOAD)
  const [elementsLoaded, setElementsLoaded] = useState<{ elements: number, isLoading: boolean }>({ elements: 0, isLoading: true })

  function handleProductsPaging(currPppage: number = 0) {
    return MIN_PRODUCT_TO_LOAD + currPppage;
  }

  const getPagedProducts = () => {
    setElementsLoaded({ elements: elementsLoaded.elements, isLoading: true })

    axios.get('/api/pagedProducts', { params: { type: props.type, start: products.length, limit: MIN_PRODUCT_TO_LOAD } })
      .then((response) => {

        console.log(response.data)

        let newProducts = response.data
        let totalCurrentProducts = products.length + newProducts.length

        setProductsPaged(handleProductsPaging(elementsLoaded.elements))
        setProducts(products.concat(newProducts))

        setElementsLoaded({ elements: totalCurrentProducts, isLoading: false })

      })
      .catch((response) => { console.log("ERRORE") });
  }

  useEffect(() => {
    setProductsPaged(handleProductsPaging())

    axios.get('/api/countProducts', { params: { type: props.type } }).then((response) => {
      setTotalProducts(response.data as number)
    }).catch((response) => { console.log(response, totalProducts) });

    getPagedProducts()
  }, [])

  useEffect(() => {
    console.log(products)
  }, [products])

  const renderItems = (index: number) => {
    const { elements, isLoading } = elementsLoaded

    if (isLoading && index >= elements)
      return <ItemCardLoading opacity={1 - (1 / MIN_PRODUCT_TO_LOAD) * (index % MIN_PRODUCT_TO_LOAD)} />

    if (products[index])
      return <ItemCard item={products[index]} />
  }

  return (
    <CandorlanaePage>
      {/* <SlidingPanel
        onClickApply={() => { setPanelVisible(false) }}
        onClickClose={() => { setPanelVisible(false) }}
        panelVisible={panelVisible}
      /> */}
      <div className="shop-category-body">

        <h2>{props.categoryName}</h2>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchBar />
          <FilterButton
          onClick={() => { setPanelVisible(!panelVisible) }}
        // onClickApply={() => {setPanelVisible(false)}}
        />
        </div> */}
        <div className="items">
          <Grid container spacing={{ xs: 1, md: 2, lg: 2 }} columns={{ xs: 2, md: 12, lg: 16 }}>
            {Array.from(new Array(productsPaged)).map((_, index) => (
              <Grid item xs={1} md={4} lg={4} key={index}>
                {renderItems(index)}
                {/* <ItemCard item={products[index]} isFirst={false} isLast={false} /> */}

                {/* <ItemCardLoading opacity={1 - (1 / MIN_PRODUCT_TO_LOAD) * (index % MIN_PRODUCT_TO_LOAD)} isFirst={false} isLast={true} /> */}
              </Grid>
            ))}
          </Grid>
          {/* {productsPaged.pprow && _.times(
            productsPaged.pppage / productsPaged.pprow, //numero di rows
            n => <div className="items-row">
              {_.times(
                productsPaged.pprow!, //numero di prodotti per row
                n2 => {
                  let pos = (n * productsPaged.pprow!) + n2
                  const { elements, isLoading } = elementsLoaded
                  if (isLoading && pos >= elements)
                    return <ItemCardLoading key={pos} opacity={1 - (1 / MIN_PRODUCT_TO_LOAD) * (pos % MIN_PRODUCT_TO_LOAD)} isFirst={n2 == 0} isLast={n2 == (productsPaged.pprow! - 1)} />

                  if (products.at(pos))
                    return <ItemCard key={pos} item={products[pos]} isFirst={n2 == 0} isLast={n2 == (productsPaged.pprow! - 1)} />
                  return <div style={{ flex: 1, marginLeft: '20px' }} />
                }
              )
              }
            </div>)
          } */}
          {/* <LoadingComponent
            isLoading={isLoading}
            main={<div></div>
              // <ShopMain
              //   products={products}
              //   productsPaged={productsPaged}
              //   totalProducts={totalProducts}
              //   onClickLoad={() => getPagedProducts()}
              // />
            }
            loading={<ShopLoading
              productsPaged={productsPaged}
              totalProducts={totalProducts} />}
          /> */}
          <div className="load-other-products-container">
            <div className="load-other-products">
              Hai visualizzato {elementsLoaded.elements} su {totalProducts} prodotti
              <div className={`load-other-btn button-white ${elementsLoaded.elements < totalProducts ? '' : 'disabled'} no-select`} onClick={() => !elementsLoaded.isLoading && getPagedProducts()}>
                Carica altri prodotti
              </div>
            </div>
          </div>
        </div>

        {/* <div className="numbered-list-container">
          {
            Math.floor(products.length / productsPaged.pppage) + 1 > 1
            && _.times(
              products.length / productsPaged.pppage + 1,
              (i) =>
                <div
                  key={i}
                  className={`advice-index ${index == i ? "active" : ""}`}
                  onClick={() => {
                    setIndex(i)
                    getPagedProducts(productsPaged.pppage * i)
                  }}>
                  0{i + 1}
                </div>
            )
          }
        </div> */}
      </div>
    </CandorlanaePage>
  )
}

export { ShopProductsContainer }