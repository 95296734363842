import React from "react";
import { SummaryCartProductType } from "../../../types";
import { CandorlanaeImage } from '../image'
import { displayPrice } from "../../helper/displayer";

function SummaryElement(props: {
  item: SummaryCartProductType
}) {
  return (
    <div className="checkout-summary-element">
      <CandorlanaeImage
        externalImg={{ src: props.item.img, size: "normal" }}
        percentage="100%"
        containerClassName="checkout-summary-element-img-container"
        imgClassName="checkout-summary-element-img"
      />
      {/* <div className="checkout-summary-element-img" /> */}
      <div className="checkout-summary-element-info">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="checkout-summary-element-name">
            {props.item.name} {props.item.qty > 1 && `(x${props.item.qty})`}
          </div>
          <div className="checkout-summary-element-details">
            <div className="checkout-summary-element-color">
              <div style={{ height: '8px', width: '8px', backgroundColor: props.item.color.code, borderRadius: '100px', marginRight: '4px' }} />
              {props.item.color.name}
            </div>
            <div className="checkout-summary-element-dash">
              —
            </div>
            <div className="checkout-summary-element-size">
              {props.item.size}
            </div>
          </div>

        </div>
        <div className="checkout-summary-element-price">
          {displayPrice(props.item.price * props.item.qty)}
        </div>
      </div>
    </div>
  )
}

export { SummaryElement } 