import axios from "axios";
import { useContext } from "react";
import { RequestSendMailCustomerReqType } from "../../../types";
import { PopupContextType } from "../../../types/popup";
import { PopupContext } from "../context/popup_context";

export function useHandleCustomerRequest() {

  const { setPopupMessage, setMessageAndAction } = useContext(PopupContext) as PopupContextType;

  return (data?: RequestSendMailCustomerReqType) => {
    setMessageAndAction({
      message: "Invio richiesta in corso...",
      action: undefined,
    })

    console.log(process.env)

    axios.post('/auth_api/sendMailCustomerReq', data, {
      headers: {
        'authorization-api-key': process.env.REACT_APP_AUTHORIZATION_API_KEY!
      }
    })
      .then(() => {
        setPopupMessage("Richiesta inviata!")
      })
      .catch(() => {
        setPopupMessage("Errore durante l'invio della richiesta")
      })
  }
}