import React, { useContext, useState } from 'react';
import { ProductType, SummaryCartProductType } from '../../../types';
import { CartContext } from '../../helper/context/cart_context';
import { CandorlanaeImage } from '../image'
import { cartCookie, getProduct, removeFromCookie } from '../../helper/cookie_helper';
import { displayPrice } from '../../helper/displayer';
import { convertedImageName } from '../../helper/replacer_img_name';
import remove_icon from '../../assets/icon/close.png'
import { useNavigate } from 'react-router-dom';
import { HandleRemoveFromCart } from '../../helper/handlers/cart_handler';
import { CircleColor } from '../circle_color';

function CartElement(props: {
  item: SummaryCartProductType,
  onAdd: React.MouseEventHandler<HTMLDivElement> | undefined,
  onRemove: () => any
}) {

  const [state, setState] = useContext(CartContext)
  const navigate = useNavigate()

  const propsItem = props.item;

  const handleClickRemove = () => {

  }

  const handleClickItem = (id: string) => {
    navigate(`/product/${id}`)
  }

  console.log(propsItem)

  return (
    <div className="cartitem-container">
      <CandorlanaeImage
        onClick={() => handleClickItem(propsItem._id)}
        externalImg={{ src: propsItem?.img, size: "normal" }}
        percentage={'100%'}
        containerClassName="cartitem-img-container"
        imgClassName="cartitem-img"
      />

      <div className="cartitem-body">
        <div className="cartitem-body-info">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0px' }}>
            <div className="cartitem-body-name">
              {propsItem.name}
            </div>
            <img src={remove_icon} className="cartitem-body-remove-icon" onClick={() => HandleRemoveFromCart(propsItem._id, propsItem.size, setState)} />
            {/* <u className="cartitem-body-remove-text" onClick={() => handleClickRemove()}>X</u> */}
          </div>
          {/* <div className="cartitem-body-description">
            {propsItem.description}
          </div> */}
          <div className="cartitem-body-details">
            <div className="cartitem-body-details-container">
              <div style={{ marginBottom: '3px' }}>
                Colore:
              </div>
              <div className="cartitem-body-color">
                <CircleColor color1={propsItem.color.code} color2={propsItem.color.code2} className="cartitem-body-color-circle" />
                {/* <div className="cartitem-body-color-circle" style={{ backgroundColor: propsItem.color.code }} /> */}
                {propsItem.color.name}
              </div>
            </div>
            <div className="cartitem-body-details-container">
              <div style={{ marginBottom: '3px' }}>
                Taglia:
              </div>
              <div className="cartitem-body-size">
                {propsItem.size}
              </div>
            </div>
          </div>
        </div>
        <div className="cartitem-body-price">
          <div className="cartitem-body-price-value">
            {displayPrice(propsItem.price)}
          </div>
          <div className="cartitem-body-price-container">
            <div className={`no-select cartitem-body-price-remove ${propsItem.qty > 1 ? 'enable' : 'disable '}`} onClick={() => {
              getProduct(propsItem._id, propsItem.color)!.qty > 1 && props.onRemove()
            }}>
              —
            </div>
            <div className="cartitem-body-price-qty">
              {getProduct(propsItem._id, propsItem.color)?.qty}
            </div>
            <div className="no-select cartitem-body-price-add" onClick={props.onAdd}>
              +
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CartElement }