import React from "react";
import { useHandleAddToCart } from "../helper/handlers/cart_handler";
import '../styles/add_to_cart.scss'

function AddToCart(props: {
  className?: string,
  colorButton: 'orange' | 'pink' | 'fucsia',
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => any,
  _id: string,
  modelIndex: number,
  sizeIndex: number,
  disabled?: boolean,
}) {
  const handleAddToCart = useHandleAddToCart()

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    props.onClick && props.onClick(event)
    handleAddToCart(props._id, props.modelIndex, props.sizeIndex)
  }

  return (
    <div
      className={`${props.className} button-${props.colorButton} ${props.disabled ? 'disabled' : ''} no-select`}
      //style={{ pointerEvents: props.disabled ? 'none' : 'auto' }}
      onClick={props.disabled ? undefined : onClick}>
      Aggiungi al carrello
    </div>
  )
}

export { AddToCart }