import { height } from "@mui/system";
import _ from "lodash";
import React from "react";
import { Skeleton } from "../../components/skeleton";

function CartItemsLoading(props: {
  n: number
}) {
  return (
    <>
      {_.times(props.n,
        (n) => (
          <div className="cartitem-container" style={{ opacity: 1 / n }}>
            <Skeleton className="cartitem-img-container" percentage="100%" />
            <div className="cartitem-body">
              <div className="cartitem-body-info">
                <div className="cartitem-body-name">
                  <Skeleton style={{ height: '22px', width: '40%' }} />
                </div>
                <div className="cartitem-body-details">
                  <div className="cartitem-body-details-container">
                    <div style={{ marginBottom: '3px' }}>
                      Colore:
                    </div>
                    <div className="cartitem-body-color">
                      <Skeleton style={{ height: '15px', width: '100%' }} />
                    </div>
                  </div>
                  <div className="cartitem-body-details-container">
                    <div style={{ marginBottom: '3px' }}>
                      Taglia:
                    </div>
                    <div className="cartitem-body-size">
                      <Skeleton style={{ height: '15px', width: '100%' }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="cartitem-body-price">
                <Skeleton style={{ height: '22px', width: 'max(30%, 60px)' }} />
                <div className="cartitem-body-price-container">
                  <div className={`no-select cartitem-body-price-remove enable`}>
                    —
                  </div>
                  <div className="cartitem-body-price-qty">
                    <Skeleton style={{ height: '19px', width: '20px' }} />

                  </div>
                  <div className="no-select cartitem-body-price-add">
                    +
                  </div>
                </div>
              </div>
            </div>
          </div>

        ))}
    </>
  )
}

export { CartItemsLoading }