import { Location } from "history";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PopupContextType } from "../../../types/popup";
import { PopupContext } from "../context/popup_context";

function useOnLocationChange(onLocationChange: ((arg0: Location) => any) | undefined) {
  const location = useLocation()
  const { setMessageAndAction } = useContext(PopupContext) as PopupContextType;

  useEffect(() => {
    onLocationChange && onLocationChange(location)
    setMessageAndAction({ message: "", action: undefined })
  }, [location])
}

export { useOnLocationChange }